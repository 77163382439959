export const NoMoreItemsArrow = () => {
  return (
    <svg
      width="122"
      height="154"
      viewBox="0 0 122 154"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.51074 131.18C14.5612 117.806 27.9917 140.283 36.6764 146.911C69.7112 172.119 97.0417 58.9446 99.611 41.524C100.083 38.3217 102.33 8.32623 101.239 8.96248C98.6152 10.4916 87.8242 23.6989 90.168 19.6393C93.6305 13.6421 99.3596 9.38423 102.882 3.28367C104.632 0.251833 116.968 25.3499 119.464 28.3824"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
      />
    </svg>
  );
};
