import { gql, useMutation } from "@apollo/client";

const MOVE_COOKING_ORDER_BACK_INTO_SCHEDULED = gql`
  mutation MOVE_COOKING_ORDER_BACK_INTO_SCHEDULED($store_order_id: Int) {
    move_cooking_order_back_into_scheduled(store_order_id: $store_order_id)
  }
`;

export const useMoveCookingOrderBackIntoScheduled = () => {
  const [moveCookingOrderBackIntoScheduled, { loading, error }] = useMutation(
    MOVE_COOKING_ORDER_BACK_INTO_SCHEDULED,
  );

  return {
    moveCookingOrderBackIntoScheduled,
    loading,
    error,
  };
};
