import React from "react";
import { AlertWidgetTemporaryContextProvider } from "./AlertWidgetTemporaryContext";
import { AppMetadataProvider } from "./AppMetadataContext";
import { CurrentCookProvider } from "./CurrentCookContext";
import { DisplayContextProvider } from "./DisplayContext";
import { EmployeeProvider } from "./EmployeeContext";
import KdsStatsigProvider from "./KdsStatsigProvider";
import { LocationProvider } from "./LocationContext";
import { PeekContextProvider } from "./PeekContext";
import { PrepStationProvider } from "./PrepStationContext";
import { ScheduledOrdersProvider } from "./ScheduledOrdersContext";
import { SummaryViewContextProvider } from "./SummaryViewContext";

type ContextsContainerProps = {
  children: React.ReactElement;
};

export default function ContextsContainer({
  children,
}: ContextsContainerProps) {
  return (
    <AppMetadataProvider>
      <DisplayContextProvider>
        <LocationProvider>
          <EmployeeProvider>
            <PrepStationProvider>
              <CurrentCookProvider>
                <AlertWidgetTemporaryContextProvider>
                  <KdsStatsigProvider>
                    <ScheduledOrdersProvider>
                      <SummaryViewContextProvider>
                        <PeekContextProvider>{children}</PeekContextProvider>
                      </SummaryViewContextProvider>
                    </ScheduledOrdersProvider>
                  </KdsStatsigProvider>
                </AlertWidgetTemporaryContextProvider>
              </CurrentCookProvider>
            </PrepStationProvider>
          </EmployeeProvider>
        </LocationProvider>
      </DisplayContextProvider>
    </AppMetadataProvider>
  );
}
