import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLocationBySlug } from "../features/KitchenDisplaySystem/hooks/useLocationBySlug";

const DEFAULT_LOCATION_SLUG = "lafayette";

export type LocationContextType = {
  id?: number;
  name?: string;
  slug: string;
  updateLocation: (locationSlug: string) => void;
};

export const LocationContext = createContext<LocationContextType>({
  id: undefined,
  name: undefined,
  slug: DEFAULT_LOCATION_SLUG,
  updateLocation: (locationSlug: string) => {},
});

export const LocationProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [currentLocation, setCurrentLocation] = useState<string>(
    DEFAULT_LOCATION_SLUG,
  );
  const [locationId, setLocationId] = useState<number | undefined>();
  const [locationName, setLocationName] = useState<string | undefined>();

  const routeLocation = useLocation();

  const { data: locationData } = useLocationBySlug(currentLocation);

  useEffect(() => {
    const { all_location_details } = locationData || {};
    const { id, name } = all_location_details || {};
    if (id && name) {
      setLocationId(id);
      setLocationName(name);
    }
  }, [locationData]);

  const locationRedirect = (locationSlug: string) => {
    if (locationSlug && locationSlug != currentLocation) {
      const params = new URLSearchParams(routeLocation.search);
      params.set("location", locationSlug);
      window.location.replace(window.location.origin + "?" + params);
      setCurrentLocation(locationSlug);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(routeLocation.search);
    const locationSlug = searchParams.get("location");

    if (locationSlug) {
      setCurrentLocation(locationSlug);
    }
  }, []);

  return (
    <LocationContext.Provider
      value={{
        id: locationId,
        name: locationName,
        slug: currentLocation,
        updateLocation: locationRedirect,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = () => {
  const locationContext = useContext(LocationContext);

  if (!locationContext) {
    throw new Error(
      "You may not use LocationContext outside of LocationProvider",
    );
  } else {
    return locationContext;
  }
};
