import { useAcknowledgeNotificationMutation } from "../../../graphql/generated";

export const useAcknowledgeOrderNotification = (notificationId?: number) => {
  const [acknowledgeNotificationMutation] =
    useAcknowledgeNotificationMutation();

  return {
    acknowledgeNotificationMutation,
  };
};
