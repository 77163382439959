import { gql, useQuery } from "@apollo/client";

const PREP_STATIONS_QUERY = gql`
  query PREP_STATIONS_QUERY($location_id: Int) {
    location(location_id: $location_id) {
      id
      prep_stations {
        id
        name
        prep_station_type_id
        tickets {
          is_completed
          line_item {
            order {
              id
              status
            }
          }
        }
      }
      store_menu_prep_stations {
        id
        store_menu_id
        prep_station {
          id
          name
        }
      }
      store_menu_category_prep_stations {
        id
        store_menu_category_id
        prep_station {
          id
          name
        }
      }
      store_menu_item_prep_stations {
        id
        store_menu_item_id
        prep_station {
          id
          name
        }
      }
    }
  }
`;

export const usePrepStations = (locationId?: number) => {
  const { data, error, loading, refetch } = useQuery(PREP_STATIONS_QUERY, {
    variables: { location_id: locationId },
    skip: locationId === undefined,
  });

  return { data, error, loading, refetch };
};
