import React, { useState } from "react";

type NumberPadButtonV2Props = {
  value: any;
  className?: string;
  onClick: (event: any) => void;
  children?: React.ReactNode;
};

export const NumberPadButtonV2 = ({
  value,
  className,
  onClick,
  children,
}: NumberPadButtonV2Props) => {
  const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });

  const numberPadButtonClick = (value: any) => {
    onClick(value);
    setOpacityStyle({ opacity: 0.75 });
    setTimeout(() => {
      setOpacityStyle({ opacity: 1 });
    }, 150);
  };

  const defaultStyle = "w-1/3 flex justify-center items-center";

  return (
    <td
      className={className ? className : defaultStyle}
      style={{ ...opacityStyle, height: 70 }}
      onClick={() => numberPadButtonClick(value)}
    >
      <div className="w-full h-full text-white font-bold flex justify-center items-center">
        {children ? (
          children
        ) : (
          <span className="font-bold text-6xl">{value}</span>
        )}
      </div>
    </td>
  );
};
