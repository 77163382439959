import { useEffect, useState } from "react";
import {
  GroupedLineItems,
  useDisplayContext,
} from "../../../../contexts/DisplayContext";
import { usePrepStationContext } from "../../../../contexts/PrepStationContext";
import { useSummaryViewContext } from "../../../../contexts/SummaryViewContext";
import { KdsTicket } from "../../../../graphql/generated";
import { SummaryViewItem } from "../../../../types";
import { Ticket } from "./Ticket";

type TicketGridProps = { tickets: KdsTicket[] | undefined; ticketsRef: any };

export const TicketGrid = ({ tickets, ticketsRef }: TicketGridProps) => {
  const { isExpo } = usePrepStationContext();
  const { isEnabled: showSummaryView, itemName } = useSummaryViewContext();
  const [groupedItems, setGroupedItems] = useState<GroupedLineItems | null>(
    new Map(),
  );
  const { setGroupedItems: setGroupedItemsSummaryView } = useDisplayContext();
  const columnCount = isExpo && !showSummaryView ? 4 : 3;

  // useEffect to handle summary view for KDS
  useEffect(() => {
    let tempMap = new Map<string, SummaryViewItem>();
    tickets?.forEach((ticket: KdsTicket) => {
      ticket?.line_items?.forEach((lineItem) => {
        // only count items that are only expecting to be cooked
        if (lineItem?.status !== "CREATED") return;

        let summaryViewItem: SummaryViewItem = {
          count: 0,
          itemName: "",
          tickets: [],
        };
        summaryViewItem.itemName = lineItem?.name ?? "";
        summaryViewItem.count = lineItem?.quantity ?? 0;
        let existingItem = tempMap?.get(summaryViewItem.itemName);
        if (existingItem) {
          existingItem.count += summaryViewItem.count;
          if (!existingItem.tickets.includes(ticket)) {
            existingItem.tickets.push(ticket);
            tempMap.set(summaryViewItem.itemName, existingItem);
          }
        } else {
          summaryViewItem.tickets.push(ticket);
          tempMap.set(summaryViewItem.itemName, summaryViewItem);
        }
      });
    });
    setGroupedItems(tempMap);
  }, [tickets]);

  useEffect(() => {
    setGroupedItemsSummaryView(groupedItems);
  }, [groupedItems, setGroupedItemsSummaryView]);

  return (
    <div
      className="flex flex-col h-full overflow-y-hidden no-scrollbar"
      ref={ticketsRef}
    >
      <div className="flex h-full flex-col flex-wrap pb-8 items-start justify-start content-start">
        {showSummaryView && itemName !== "" && groupedItems
          ? groupedItems.get(itemName)?.tickets?.map((ticket: KdsTicket) => {
              return (
                <Ticket
                  key={ticket.id}
                  ticket={ticket}
                  columns={columnCount}
                  ticketsRef={ticketsRef}
                />
              );
            })
          : tickets?.map((ticket: KdsTicket) => {
              return (
                <Ticket
                  key={ticket.id}
                  ticket={ticket}
                  columns={columnCount}
                  ticketsRef={ticketsRef}
                />
              );
            })}
      </div>
    </div>
  );
};
