import { useEffect, useState } from "react";
import { useCurrentCookContext } from "../../../../contexts/CurrentCookContext";
import { LoginPageStationsView } from "./LoginPageStationsView";
import { LoginStationPicker } from "./LoginStationPicker";
import { NumberPadV2 } from "./NumberPadV2";
import { PinPlaceholdersV2 } from "./PinPlaceholdersV2";

export const CookLoginPageV2 = () => {
  const [pinCode, setPinCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { handleCookAuthentication } = useCurrentCookContext();
  const [shake, setShake] = useState(false);
  const [showLoginStationPicker, setShowLoginStationPicker] = useState(false);

  const onNumberPadButtonClick = (value: any) => {
    if (value === "CLEAR") {
      setPinCode("");
    } else if (value === "BACKSPACE") {
      setPinCode(`${pinCode.slice(0, -1)}`);
    } else if (pinCode.length < 6) {
      setPinCode(`${pinCode.concat(value)}`);
    } else if (pinCode.length >= 6 && errorMessage) {
      setPinCode(String(value));
    }

    setErrorMessage("");
  };

  useEffect(() => {
    if (pinCode.length >= 6) {
      handleCookAuthentication(pinCode).then((result) => {
        const { status } = result;

        if (status) {
          setErrorMessage("");
        } else {
          setErrorMessage("Wrong Pin. Please try again");
        }
      });
    }
  }, [pinCode]);

  useEffect(() => {
    if (errorMessage) {
      setShake(true);

      const timeout = setTimeout(() => {
        setShake(false);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [errorMessage]);

  return (
    <div className="grid h-screen grid-cols-2">
      <LoginPageStationsView
        setShowLoginStationPicker={setShowLoginStationPicker}
      />
      <div className="flex h-full flex-col justify-between bg-black pb-20 pt-20">
        <div className="flex flex-col justify-center">
          <div className={`${shake ? "animate-shake" : ""}`}>
            <PinPlaceholdersV2 numberOfPins={6} filledPins={pinCode.length} />
          </div>
          <div className="text-red-500 text-center m-8">{errorMessage}</div>
        </div>
        <div className="flex justify-center">
          <NumberPadV2 onNumberPadButtonClick={onNumberPadButtonClick} />
        </div>
      </div>
      {showLoginStationPicker && (
        <LoginStationPicker
          setShowLoginStationPicker={setShowLoginStationPicker}
        />
      )}
    </div>
  );
};
