import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IDDLE_PROMPT_TIMEOUT } from "../../../utils/iddleTimeoutContants";
import { useCurrentCookContext } from "../../../contexts/CurrentCookContext";

type IdleLogoutModalProps = {
  setShowIdleLogoutModal: Dispatch<SetStateAction<boolean>>;
  iddleTimeout: ReturnType<typeof setTimeout> | null;
};

export const IdleLogoutModal = ({
  setShowIdleLogoutModal,
  iddleTimeout,
}: IdleLogoutModalProps) => {
  const { currentCook, onCookLogout } = useCurrentCookContext();
  const firstName = currentCook?.first_name?.split(" ")?.[0];
  const [countDown, setCountDown] = useState(IDDLE_PROMPT_TIMEOUT / 1000);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [countDown]);

  useEffect(() => {
    if (countDown <= 0) {
      if (iddleTimeout) {
        clearTimeout(iddleTimeout);
      }

      setShowIdleLogoutModal(false);
      onCookLogout();
    }
  }, [countDown]);

  return (
    <>
      <div className="p-4 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className="p-10 border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none w-full text-black"
          style={{ maxWidth: 650 }}
        >
          <div className="flex justify-center items-center">
            <h1 className="text-4xl text-center tracking-tight mr-3">
              Hey <span className="font-bolder">{firstName}</span>, are you
              still there?
            </h1>
            <FontAwesomeIcon icon={faClock} size="2x" />
          </div>
          <p className="text-xl text-center mt-5">
            You will be logged out in{" "}
            <span className="text-red-600">{countDown}</span> seconds
          </p>
          <div className="flex mt-8 justify-center">
            <button
              className="border-0 bg-red-600 text-lg text-white rounded px-5 py-3 font-normal mr-8"
              onClick={() => {
                onCookLogout();
                setShowIdleLogoutModal(false);
              }}
            >
              Change Cook
            </button>
            <button
              className="border-0 bg-green-600 text-lg text-white rounded px-5 py-3 font-normal"
              onClick={() => {
                if (iddleTimeout) {
                  clearTimeout(iddleTimeout);
                }
                setShowIdleLogoutModal(false);
              }}
            >
              Stay Logged In
            </button>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
