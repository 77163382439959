import { useAuth } from "@localkitchens/passwordless-auth";
import { useEffect, useRef, useState } from "react";
import { IdleTimerProvider } from "react-idle-timer";
import { useLocation } from "react-router-dom";
import { useCurrentCookContext } from "../../contexts/CurrentCookContext";
import { useEmployeeContext } from "../../contexts/EmployeeContext";
import { useLocationContext } from "../../contexts/LocationContext";
import { usePrepStationContext } from "../../contexts/PrepStationContext";
import { IDDLE_SESSION_TIMEOUT } from "../../utils/iddleTimeoutContants";
import { KitchenDisplaySystem } from "./KitchenDisplaySystem";

type ReactNativeWindow = typeof window & {
  ReactNativeWebView: {
    postMessage: (data: any) => void;
  };
};

export const KitchenDisplaySystemController = () => {
  const { slug: currentLocation, id: currentLocationId } = useLocationContext();

  const { logout, user } = useAuth();
  const location = useLocation();
  const { setEmployee } = useEmployeeContext();
  const { currentPrepStationId, prepStationData, loadingPrepStations, isExpo } =
    usePrepStationContext();
  const { onCookLogout, currentCook, setCurrentCook } = useCurrentCookContext();

  // Expo prep station can modify actual state of the order
  // Idle cook modal
  const [showIdleLogoutModal, setShowIdleLogoutModal] = useState(false);
  // Time out reference
  const iddleTimeoutRef: { current: ReturnType<typeof setTimeout> | null } =
    useRef(null);

  // TODO - can we move this to context as well?
  // Current Cook

  useEffect(() => {
    if (!currentCook && iddleTimeoutRef.current) {
      setCurrentCook(undefined);
    }
  }, [currentCook, setCurrentCook]);

  // Load cook from last saved in local storage or make them log in again
  useEffect(() => {
    const localCook = window.localStorage.getItem("currentCook");

    if (localCook) {
      const cook = JSON.parse(localCook);
      setCurrentCook(cook);
      setEmployee(cook);
    }
  }, []);

  // Set user for datadog
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let station = searchParams.get("station");

    if (!station) {
      station = window.localStorage.getItem("savedStation");
    }

    const [currentPrepStation] = (
      prepStationData?.location?.prep_stations || []
    ).filter(
      (prepStation: any) =>
        prepStation?.name?.replace(/ /g, "").toLowerCase() ==
        station?.replace(/ /g, "").toLowerCase(),
    );

    // Inject the current prep station id into the RN WebView if available
    if ("ReactNativeWebView" in window && currentPrepStation) {
      (window as ReactNativeWindow).ReactNativeWebView.postMessage(
        currentPrepStationId || "",
      );
    }
  }, [currentPrepStationId, currentLocation, prepStationData]);

  if (loadingPrepStations || currentPrepStationId === undefined) {
    return (
      <div className="text-white flex flex-col w-screen h-screen items-center justify-center bg-black">
        <div className="font-medium text-3xl">Loading...</div>
        <div
          onClick={() => {
            logout({
              returnTo: window.location.origin,
            });
          }}
          className="mt-16 px-4 py-2 bg-gray-800 rounded-md hover:bg-gray-700 hover:cursor-pointer"
        >
          LOGOUT
        </div>
      </div>
    );
  }

  const onIdle = () => {
    if (currentCook) {
      onCookLogout();
    }
  };

  return (
    <IdleTimerProvider timeout={IDDLE_SESSION_TIMEOUT} onIdle={onIdle}>
      <KitchenDisplaySystem
        showIdleLogoutModal={showIdleLogoutModal}
        setShowIdleLogoutModal={setShowIdleLogoutModal}
        iddleTimeout={iddleTimeoutRef.current}
      />
    </IdleTimerProvider>
  );
};
