import React from "react";

interface Props {
  num: number;
  active: boolean;
  highlight?: boolean;
}

export const CountIndicatorView = React.memo(
  ({ num, active, highlight }: Props) => {
    const activeStyle = highlight
      ? "bg-lfg-orange text-white"
      : "bg-gray-700 text-white";
    const inactiveStyle =
      highlight && num > 0
        ? "bg-black text-lfg-orange border border-lfg-orange"
        : "bg-black text-gray-700 border border-gray-700";
    return (
      <div
        className={`mx-2 w-8 h-8 flex flex-row items-center justify-center rounded-lg text-base ${
          active ? activeStyle : inactiveStyle
        }`}
      >
        {num}
      </div>
    );
  },
);
