import { useCallback } from "react";
import { LogOut, Sidebar } from "react-feather";
import { useHistory } from "react-router-dom";
import { useAppMetadataContext } from "../../../../contexts/AppMetadataContext";
import { useCurrentCookContext } from "../../../../contexts/CurrentCookContext";
import { useLocationContext } from "../../../../contexts/LocationContext";
import { usePeekContext } from "../../../../contexts/PeekContext";
import { usePrepStationContext } from "../../../../contexts/PrepStationContext";
import { useSummaryViewContext } from "../../../../contexts/SummaryViewContext";
import { useCookEmployee } from "../../hooks/useCookEmployee";
import { CurrentFilter } from "../../types";
import { CountIndicatorView } from "../CountIndicatorView";
import { StationDropdownV2 } from "./StationDropdownV2";

type TopBarV2Props = {
  cookingCount: number;
  readyCount: number;
  scheduledCount: number;
  singleStationOrderCount: number;
  itemsReadyOrderCount: number;
  onFilterClick: (filter: CurrentFilter) => void;
};

export const TopBarV2 = ({
  cookingCount,
  readyCount,
  scheduledCount,
  singleStationOrderCount,
  itemsReadyOrderCount,
  onFilterClick,
}: TopBarV2Props) => {
  const {
    currentPrepStationId,
    handlePrepStationChange,
    isExpo,
    currentFilter,
    prepStationData,
    showAllStations,
    setShowAllStations,
  } = usePrepStationContext();
  const { id: currentLocationId, name: currentLocationName } =
    useLocationContext();
  const prepStations = prepStationData?.location?.prep_stations || [];
  const { currentCook, onCookLogout } = useCurrentCookContext();

  const onSingleStationFilterClick = useCallback(() => {
    if (isExpo) {
      onFilterClick("single-station");
    }
  }, [isExpo, onFilterClick]);

  return (
    <div
      className={`flex flex-none justify-between items-center py-6 sticky top-0  z-50 ${
        currentFilter === "cooking" && "bg-black"
      }`}
    >
      <ExpoViewOptions
        currentFilter={currentFilter}
        cookingCount={cookingCount}
        readyCount={readyCount}
        scheduledCount={scheduledCount}
        itemsReadyOrderCount={itemsReadyOrderCount}
        onFilterClick={onFilterClick}
        isExpo={isExpo}
        currentPrepStationId={currentPrepStationId || 0}
      />
      <div
        className={`h-[75px] font-inter rounded-xl border-2 ${
          currentFilter === "single-station"
            ? "bg-lfg-orange animate-bounce"
            : "bg-black"
        } border-lfg-orange px-4 py-2 text-lg text-gray-200 select-none`}
        onClick={() => onSingleStationFilterClick()}
      >
        Single Station
        <div className="flex flex-row items-center justify-center">
          <div className="w-8 h-8 flex flex-row items-center justify-center rounded-lg text-base bg-lfg-orange text-white">
            {singleStationOrderCount}
          </div>
        </div>
      </div>
      <MenuOptions
        currentLocationId={currentLocationId}
        currentPrepStationId={currentPrepStationId}
        prepStations={prepStations}
        onPrepStationChange={handlePrepStationChange}
        isExpo={isExpo}
        onCookLogout={onCookLogout}
        showAllStations={showAllStations}
        setShowAllStations={setShowAllStations}
        currentCook={currentCook}
        currentLocationName={currentLocationName || ""}
      />
    </div>
  );
};

type ExpoViewOptionsProps = {
  currentFilter: string;
  cookingCount: number;
  scheduledCount: number;
  readyCount: number;
  itemsReadyOrderCount: number;
  onFilterClick: any;
  isExpo: boolean;
  currentPrepStationId: number;
};

const ExpoViewOptions = ({
  currentFilter,
  cookingCount,
  readyCount,
  scheduledCount,
  itemsReadyOrderCount,
  onFilterClick,
  isExpo,
}: ExpoViewOptionsProps) => {
  const { deselectLineItem: onDeselectPeek } = usePeekContext();
  const { isEnabled, setIsEnabled, setItemName } = useSummaryViewContext();
  const showSummaryViewTab = isExpo && currentFilter === "cooking";
  const displayAllItemsReceivedCounter = isExpo;

  const handleFilterClick = (filter: string) => {
    onFilterClick(filter);
    if (filter !== "cooking") {
      setItemName("");
      setIsEnabled(false);
    }
    onDeselectPeek?.();
  };

  const handleSummaryViewClick = () => {
    if (isEnabled) {
      setItemName("");
    }
    setIsEnabled(!isEnabled);
  };

  const inactiveStyle = "text-gray-700 bg-black";
  const activeStyle = "text-white bg-gray-800";

  return (
    <div className="flex gap-x-4">
      {showSummaryViewTab && (
        <button
          className={`flex px-1 py-4 h-[75px] rounded-xl ${
            !isEnabled ? "" : "bg-gray-800"
          } border-gray-700 w-20 border-2 items-center justify-center`}
          onClick={handleSummaryViewClick}
        >
          <Sidebar color="#FFFFFF" size={48} />
        </button>
      )}
      <div
        className={`text-2xl font-inter h-[75px] ${
          isExpo && currentFilter !== "cooking" ? "ml-24" : ""
        } flex`}
        role="group"
      >
        <button
          type="button"
          className={`flex h-full items-center justify-start rounded-l-xl border border-gray-700 py-2 px-4 text-2xl font-inter ${
            currentFilter === "cooking" ? activeStyle : inactiveStyle
          }`}
          onClick={() => handleFilterClick("cooking")}
        >
          <CountIndicatorView
            num={cookingCount}
            active={currentFilter === "cooking"}
          />
          Cooking
        </button>
        {displayAllItemsReceivedCounter && (
          <button
            type="button"
            className={`flex items-center justify-start border border-gray-700 py-2 px-4 text-xl font-inter font-medium ${
              currentFilter === "items-ready" ? activeStyle : inactiveStyle
            }`}
            onClick={() => {
              handleFilterClick("items-ready");
            }}
          >
            <CountIndicatorView
              num={itemsReadyOrderCount}
              active={currentFilter === "items-ready"}
              highlight={true}
            />
            <div>
              <p>Items</p>
              <p>Ready</p>
            </div>
          </button>
        )}
        {isExpo && (
          <button
            type="button"
            className={`flex items-center justify-start border border-gray-700 py-2 px-4 text-2xl font-inter font-medium ${
              currentFilter === "scheduled" ? activeStyle : inactiveStyle
            }`}
            onClick={() => {
              handleFilterClick("scheduled");
            }}
          >
            <CountIndicatorView
              num={scheduledCount}
              active={currentFilter === "scheduled"}
            />
            Scheduled
          </button>
        )}
        <button
          type="button"
          className={`flex items-center justify-start rounded-r-xl border border-gray-700 py-2 px-4 text-2xl font-inter font-medium ${
            currentFilter === "ready"
              ? activeStyle + "border-[#FFD953] bg-gray-800"
              : inactiveStyle + " border-gray-700"
          }`}
          onClick={() => {
            handleFilterClick("ready");
          }}
        >
          <CountIndicatorView
            num={readyCount}
            active={currentFilter === "ready"}
          />
          Ready
        </button>
      </div>
    </div>
  );
};

type CompletedItemTrackerProps = {
  currentCook: any;
};
const CompletedItemTracker = ({ currentCook }: CompletedItemTrackerProps) => {
  const { data } = useCookEmployee(currentCook.id);
  return (
    <div
      className="mx-2 flex flex-row items-center justify-center rounded-lg bg-gray-700 px-2 text-base text-white"
      style={{ height: "32px" }}
    >
      {data?.cook.completed_item_count >= 10000
        ? `${Math.floor(data?.cook.completed_item_count / 1000)}K`
        : data?.cook.completed_item_count}
    </div>
  );
};

type MenuOptionsProps = {
  currentPrepStationId?: number;
  prepStations: Array<any>;
  onPrepStationChange: any;
  isExpo: boolean;
  onCookLogout: any;
  showAllStations: any;
  setShowAllStations: any;
  currentCook: any;
  currentLocationId: any;
  currentLocationName: string;
};

const MenuOptions = ({
  currentPrepStationId,
  prepStations,
  onPrepStationChange,
  isExpo,
  onCookLogout,
  showAllStations,
  setShowAllStations,
  currentCook,
  currentLocationId,
  currentLocationName,
}: MenuOptionsProps) => {
  const { isAdmin } = useAppMetadataContext();

  const history = useHistory();

  const setRouteToSettings = () => {
    history.push("/settings");
  };

  return (
    <div className="flex justify-end gap-x-3 w-[500px] h-[75px]">
      {isAdmin ? (
        <button
          onClick={setRouteToSettings}
          type="button"
          className=" flex items-center rounded-xl border-2 bg-black border-gray-700 px-4 py-2 text-2xl ml-3 text-gray-200"
          style={{
            fontFamily: "Inter",
            fontWeight: "600",
            fontSize: "22px",
          }}
        >
          {currentLocationName}
        </button>
      ) : null}

      <StationDropdownV2
        currentLocationId={currentLocationId}
        currentPrepStationId={currentPrepStationId}
        prepStations={prepStations}
        onPrepStationChange={onPrepStationChange}
        isExpo={isExpo}
        onCookLogout={onCookLogout}
        showAllStations={showAllStations}
        setShowAllStations={setShowAllStations}
      />
      <span className="flex rounded-xl bg-black shadow-sm">
        <button
          type="button"
          className="flex flex-nowrap items-center rounded-l-xl border-y-2 border-l-2 border-gray-700 px-4 text-sm font-medium text-gray-200"
          style={{
            fontFamily: "Inter",
            fontWeight: "600",
            fontSize: "22px",
          }}
        >
          <CompletedItemTracker currentCook={currentCook} />

          {currentCook?.first_name}
        </button>
        <button
          onClick={() => {
            onCookLogout();
          }}
          type="button"
          className=" flex items-center rounded-r-xl border-2 border-gray-700 px-4 py-2 text-sm font-medium"
        >
          <LogOut color="#CFCFCF" size={48} />
        </button>
      </span>
    </div>
  );
};
