import { Check, CornerUpLeft } from "react-feather";
import { useSummaryViewContext } from "../../../../../contexts/SummaryViewContext";
import { LoadingSpinner } from "../../../../../ui/components/LoadingSpinner/LoadingSpinner";
import { formatPassedSinceDate } from "../../../../../utils/formatTime";

type TicketHeaderProps = {
  ticketCounter?: number;
  name?: string;
  cookingAtTime: Date;
  status: string;
  headerCSS: string;
  onHeaderClick: () => void;
  showLoadingSpinner: boolean;
  showCheckMark: boolean;
  animateCheckMark: boolean;
  displaySingleStationBadge?: boolean;
};

export const TicketHeader = ({
  ticketCounter,
  name,
  cookingAtTime,
  status,
  headerCSS,
  onHeaderClick,
  showLoadingSpinner,
  showCheckMark,
  animateCheckMark,
  displaySingleStationBadge,
}: TicketHeaderProps) => {
  const timePassedSinceStartedCooking = formatPassedSinceDate(cookingAtTime);
  const { disableKDSClick } = useSummaryViewContext();
  const handleClick = () => {
    return disableKDSClick ? () => {} : onHeaderClick();
  };

  return (
    <div
      className={`${
        showCheckMark || showLoadingSpinner ? "py-[21.5px]" : "py-6"
      } px-4 flex w-full h-full items-center justify-between rounded-t-lg transition duration-700 ease-in-out  ${headerCSS} `}
      onClick={handleClick}
    >
      {showLoadingSpinner ? (
        <div className="mb-[1px] flex flex-col items-center w-full">
          <LoadingSpinner size={8} borderWidth={4} />
        </div>
      ) : (
        <>
          {showCheckMark ? (
            <div className="flex flex-col items-center w-full">
              {status === "COOKING" ? (
                <Check
                  color="white"
                  size={32}
                  className={` ${
                    animateCheckMark ? "opacity-100" : "opacity-0"
                  } transition-opacity duration-300 ease-in mt-[1px]`}
                  strokeWidth={3}
                />
              ) : (
                <CornerUpLeft
                  color="white"
                  size={32}
                  className={` ${
                    animateCheckMark ? "opacity-100" : "opacity-0"
                  } transition-opacity duration-300 ease-in mt-[1px]`}
                  strokeWidth={3}
                />
              )}
            </div>
          ) : (
            <>
              <div className="font-medium font-inter text-xl flex items-center">
                {`#${ticketCounter} • ${name}`}
                {displaySingleStationBadge && (
                  <div
                    className={`ml-2.5 mr-2 px-[9px] py-1 rounded text-xs font-inter bg-lfg-orange outline outline-1 outline-white text-white overflow-hidden transition duration-700 ease-in-out`}
                  >
                    Single Station
                  </div>
                )}
              </div>
              <div className="font-medium font-inter text-xl">
                {timePassedSinceStartedCooking}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
