import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useIdleTimerContext } from "react-idle-timer";
import { useCurrentCookContext } from "../../contexts/CurrentCookContext";
import { useLocationContext } from "../../contexts/LocationContext";
import { Display } from "./components/Display";
import { NewOrderAlert } from "./components/NewOrderAlert";
import { PlaySoundEffect } from "./components/PlaySoundEffect";
import { CookLoginPageV2 } from "./components/v2/CookLoginPageV2";

import { useAlertWidgetTemporaryContext } from "../../contexts/AlertWidgetTemporaryContext";
import { useDisplayContext } from "../../contexts/DisplayContext";
import { usePrepStationContext } from "../../contexts/PrepStationContext";
import { useScheduledOrdersContext } from "../../contexts/ScheduledOrdersContext";
import { KdsTicket, useGetTicketsQuery } from "../../graphql/generated";
import { CurrentFilter } from "./types";

type KitchenDisplaySystemProps = {
  showIdleLogoutModal: boolean;
  setShowIdleLogoutModal: Dispatch<SetStateAction<boolean>>;
  iddleTimeout: ReturnType<typeof setTimeout> | null;
};

export const KitchenDisplaySystem = ({
  showIdleLogoutModal,
  setShowIdleLogoutModal,
  iddleTimeout,
}: KitchenDisplaySystemProps) => {
  const { id: currentLocationId } = useLocationContext();

  const { currentCook } = useCurrentCookContext();

  const idleTimer = useIdleTimerContext();

  const [filteredTickets, setFilteredTickets] = useState<KdsTicket[]>();

  const ticketQuery = useGetTicketsQuery({
    variables: {
      locationId: currentLocationId,
    },
    pollInterval: 5000,
  });
  const ticketData = ticketQuery.data as { ticket_orders: KdsTicket[] };
  const ticket_orders = ticketData?.ticket_orders ?? [];
  const { currentPrepStationId, currentFilter, isExpo } =
    usePrepStationContext();
  const { setTickets } = useDisplayContext();
  const [cookingOrderCount, setCookingOrderCount] = useState(0);
  const [readyOrderCount, setReadyOrderCount] = useState(0);
  const [itemsReadyOrderCount, setItemsReadyOrderCount] = useState(0);
  const [singleStationOrderCount, setSingleStationOrderCount] = useState(0);
  const { scheduledOrders, setScheduledOrders } = useScheduledOrdersContext();

  useEffect(() => {
    const ticketsFiltered = filterTickets(
      ticket_orders,
      currentFilter,
      currentPrepStationId ?? 0,
      isExpo,
    );
    const scheduledTickets = ticket_orders.filter((ticket) => {
      return ticket.status === "SCHEDULED";
    });
    const itemsReadydTickets = ticket_orders.filter(
      (ticket) => ticket.status === "COOKING" && ticket.are_all_items_received,
    );
    const singleStationTickets = ticketsFiltered?.filter(
      (ticket) => ticket.is_single_station_ticket,
    );

    if (scheduledOrders !== scheduledTickets) {
      setScheduledOrders(scheduledTickets);
    }
    setFilteredTickets(ticketsFiltered);
    setItemsReadyOrderCount(itemsReadydTickets.length);
    setSingleStationOrderCount(singleStationTickets?.length ?? 0);
    setTickets(ticketsFiltered ?? []);
    const newCookingOrderLength =
      ticket_orders?.filter((ticket) => ticket.status === "COOKING").length ??
      0;

    if (newCookingOrderLength > cookingOrderCount && isExpo) {
      setSoundEffectSource("/new-order.mp3");
    }
    setCookingOrderCount(newCookingOrderLength);

    if (isExpo) {
      setReadyOrderCount(
        ticket_orders?.filter((ticket) => ticket.status === "READY").length ??
          0,
      );
    } else {
      const cookingTicketsFiltered = filterTickets(
        ticket_orders,
        "cooking",
        currentPrepStationId ?? 0,
        isExpo,
      );
      const readyTicketsFiltered = filterTickets(
        ticket_orders,
        "ready",
        currentPrepStationId ?? 0,
        isExpo,
      );
      setCookingOrderCount(cookingTicketsFiltered?.length ?? 0);
      setReadyOrderCount(readyTicketsFiltered?.length ?? 0);
    }
  }, [ticket_orders, currentFilter, currentPrepStationId]);

  const [backgroundColor, setBackgroundColor] = useState("bg-black");

  const [playSoundEffect, setPlaySoundEffect] = useState(false);
  const [showNewOrderTakeover, setShowNewOrderTakeover] = useState(false);

  const { soundEffectSource, setSoundEffectSource } =
    useAlertWidgetTemporaryContext();

  const onChangeBackgroundColor = (color: string) => {
    setBackgroundColor(color);
  };

  useEffect(() => {
    if (soundEffectSource) {
      setPlaySoundEffect(true);

      const timeOut = setTimeout(() => {
        setPlaySoundEffect(false);
        setSoundEffectSource("");
      }, 2.5 * 1000);

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [soundEffectSource]);

  useEffect(() => {
    if (!currentCook && cookingOrderCount > 0) {
      if (idleTimer.isIdle()) {
        setShowNewOrderTakeover(true);
      }
    }

    if (cookingOrderCount === 0) {
      setShowNewOrderTakeover(false);
    }
  }, [currentCook, cookingOrderCount, idleTimer.isIdle()]);

  if (!currentLocationId) {
    return null;
  }
  return (
    <div className={`min-h-screen ${backgroundColor}`}>
      {!currentCook ? (
        <CookLoginPageV2 />
      ) : (
        <Display
          tickets={filteredTickets}
          cookingCount={cookingOrderCount}
          readyCount={readyOrderCount}
          singleStationOrderCount={singleStationOrderCount}
          itemsReadyOrderCount={itemsReadyOrderCount}
          onChangeBackgroundColor={onChangeBackgroundColor}
          showIdleLogoutModal={showIdleLogoutModal}
          setShowIdleLogoutModal={setShowIdleLogoutModal}
          iddleTimeout={iddleTimeout}
        />
      )}
      {playSoundEffect && <PlaySoundEffect audioSrc={soundEffectSource} />}
      {/* Show order takeover when there isn't a cook logged in */}
      {showNewOrderTakeover && (
        <NewOrderAlert
          count={cookingOrderCount}
          onAlertClick={() => {
            setShowNewOrderTakeover(false);
          }}
        />
      )}
    </div>
  );
};

//Filters tickets to only show the ones that are appropriate for the current prep station and status filter
const filterTickets = (
  tickets: KdsTicket[],
  currentFilter: CurrentFilter,
  currentPrepStationId: number,
  isExpo: boolean,
) => {
  const filterTicketStatus = tickets.filter((ticket) => {
    if (currentFilter === "cooking") {
      return ticket.status === "COOKING";
    } else if (currentFilter === "ready" && !isExpo) {
      return ticket.status === "COOKING";
    } else if (currentFilter === "ready" && isExpo) {
      return ticket.status === "READY";
    } else if (currentFilter === "scheduled") {
      return ticket.status === "SCHEDULED";
    } else if (currentFilter === "items-ready") {
      return ticket.are_all_items_received && ticket.status === "COOKING";
    } else if (currentFilter === "single-station") {
      return ticket.is_single_station_ticket && ticket.status === "COOKING";
    }
  });

  const filterTicketsForPrepStation = isExpo
    ? filterTicketStatus
    : filterTicketStatus.map((ticket) => {
        return {
          ...ticket,
          line_items: filterTicketLineItemsForPrepStation(
            ticket,
            currentPrepStationId,
          ),
        };
      });

  const filteredTickets = filterTicketsForPrepStation.filter(
    (ticket) => ticket.line_items && ticket.line_items.length > 0,
  );

  if (isExpo) {
    return filteredTickets;
  } else {
    if (currentFilter === "cooking") {
      // return list of tickets that have at least one line item that is not READY or RECEIVED
      return filteredTickets.filter((ticket) =>
        ticket.line_items?.some((lineItem) => lineItem?.status === "CREATED"),
      );
    } else if (currentFilter === "ready") {
      return filteredTickets.filter((ticket) =>
        ticket.line_items?.every((lineItem) => lineItem?.status !== "CREATED"),
      );
    }
  }
};

function filterTicketLineItemsForPrepStation(
  ticket: KdsTicket,
  prepStationId: number,
) {
  return ticket.line_items?.filter((item) =>
    item?.mapped_prep_stations?.includes(prepStationId),
  );
}
