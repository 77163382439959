import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useCurrentCookContext } from "../../../contexts/CurrentCookContext";
import { useDisplayContext } from "../../../contexts/DisplayContext";
import { usePrepStationContext } from "../../../contexts/PrepStationContext";
import { useScheduledOrdersContext } from "../../../contexts/ScheduledOrdersContext";
import { useSummaryViewContext } from "../../../contexts/SummaryViewContext";
import { KdsTicket } from "../../../graphql/generated";
import { useSegment } from "../../../utils/hooks/useSegment";
import { CookLoginModal } from "../../CookLogin";
import { IdleLogoutModal } from "../../CookLogin/components/IdleLogoutModal";
import { CurrentFilter } from "../types";
import { SummaryView } from "./SummaryView";
import { TicketGrid } from "./Ticket/TicketGrid";
import ExpoAllStationView from "./v2/ExpoAllStationView";
import { NoMoreItemsView } from "./v2/NoMoreItemsView";
import { TopBarV2 } from "./v2/TopBarV2";

type DisplayProps = {
  tickets: KdsTicket[] | undefined;
  cookingCount: number;
  readyCount: number;
  singleStationOrderCount: number;
  itemsReadyOrderCount: number;
  onChangeBackgroundColor: (color: string) => void;
  showIdleLogoutModal: boolean;
  setShowIdleLogoutModal: Dispatch<SetStateAction<boolean>>;
  iddleTimeout: ReturnType<typeof setTimeout> | null;
};

export const Display = ({
  tickets,
  cookingCount,
  readyCount,
  singleStationOrderCount,
  itemsReadyOrderCount,
  onChangeBackgroundColor,
  showIdleLogoutModal,
  setShowIdleLogoutModal,
  iddleTimeout,
}: DisplayProps) => {
  const {
    currentPrepStationId,
    currentFilter,
    setCurrentFilter,
    showAllStations,
    setShowAllStations,
    isExpo,
  } = usePrepStationContext();
  const { groupedItems } = useDisplayContext();
  const { currentCook } = useCurrentCookContext();
  const ticketsRef = useRef(null);

  const { track } = useSegment();

  const { scheduledCount, doDisplayViewedScheduledAlertWidget } =
    useScheduledOrdersContext();
  const { isEnabled: showSummaryView } = useSummaryViewContext();

  useEffect(() => {
    if (currentFilter == "scheduled") {
      doDisplayViewedScheduledAlertWidget();
    }
  }, [currentFilter]);

  const onFilterClick = (filter: CurrentFilter) => {
    setCurrentFilter(filter);
    setShowAllStations(false);

    track({ event: "Clicked Tickets Filter", properties: { filter } });

    if (filter === "cooking" || filter === "scheduled") {
      onChangeBackgroundColor("bg-black");
    } else if (filter === "ready") {
      onChangeBackgroundColor(
        "bg-gradient-to-b from-lfg-green/[.35] to-[#111111]",
      );
    }
  };

  const showSummaryViewModal = groupedItems && showSummaryView && isExpo;

  const showNoMoreItemsView =
    !showAllStations && cookingCount === 0 && currentFilter === "cooking";

  return (
    <div
      className={"relative h-screen overflow-hidden flex flex-col px-6 pb-6"}
    >
      <>
        <TopBarV2
          cookingCount={cookingCount}
          readyCount={readyCount}
          scheduledCount={scheduledCount}
          singleStationOrderCount={singleStationOrderCount}
          itemsReadyOrderCount={itemsReadyOrderCount}
          onFilterClick={onFilterClick}
        />
      </>
      {!showAllStations && !showNoMoreItemsView && (
        <div className="flex flex-row w-full h-[calc(100vh-15.5rem)] mb-6">
          {showSummaryViewModal && (
            <div className="w-1/4 h-full mb-4">
              <SummaryView groupedItems={groupedItems} />
            </div>
          )}
          <div className={`${showSummaryView ? "w-3/4" : "w-full"}`}>
            <TicketGrid tickets={tickets} ticketsRef={ticketsRef} />
          </div>
        </div>
      )}
      {showNoMoreItemsView && <NoMoreItemsView cookingCount={cookingCount} />}
      {showAllStations && <ExpoAllStationView />}
      {!currentCook && <CookLoginModal />}
      {showIdleLogoutModal && currentCook && (
        <IdleLogoutModal
          setShowIdleLogoutModal={setShowIdleLogoutModal}
          iddleTimeout={iddleTimeout}
        />
      )}
    </div>
  );
};
