import { gql, useQuery } from "@apollo/client";

export const LOCATION_BY_SLUG_QUERY = gql`
  query LOCATION_BY_SLUG_QUERY($slug: String) {
    all_location_details(slug: $slug) {
      id
      name
      slug
    }
  }
`;

export const useLocationBySlug = (slug?: string) => {
  const { data, error, loading, refetch } = useQuery(LOCATION_BY_SLUG_QUERY, {
    variables: {
      slug,
    },
    skip: !Boolean(slug),
  });
  return { data, error, loading, refetch };
};
