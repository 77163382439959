import React, { createContext, useContext, useState } from "react";
import { LogoutEmployeeType } from "../graphql/generated";
import { ApolloError, FetchResult } from "@apollo/client";
import { useLogoutEmployee } from "../features/CookLogin/hooks/useLogoutEmployee";
import {
  AuthenticateEmployeeProps,
  useAuthenticateEmployee,
} from "../features/CookLogin/hooks/useAuthenticateEmployee";

type Employee = {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  pin_code?: string;
  employee_login_id?: number;
  completed_item_count?: number;
};

type LogoutEmployeeInput = {
  employeeId: number;
  employeeLoginId: number;
  device: string;
  locationId: number;
};

export type EmployeeContextType = {
  employee: Employee;
  setEmployee: (employee: Employee) => void;
  handleLogoutEmployee: ({
    employeeId,
    employeeLoginId,
    device,
    locationId,
  }: LogoutEmployeeInput) => Promise<
    FetchResult<any, Record<string, any>, Record<string, any>>
  >;
  handleAuthenticateEmployee: (
    input: AuthenticateEmployeeProps,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
};

export const EmployeeContext = createContext<EmployeeContextType>({
  employee: {},
  setEmployee: () => {},
  handleLogoutEmployee: ({ employeeId, employeeLoginId, device, locationId }) =>
    new Promise(() => {}),
  handleAuthenticateEmployee: (input) => new Promise(() => {}),
});

export const EmployeeProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [employee, setEmployee] = useState({});
  const { handleLogoutEmployee } = useLogoutEmployee();
  const { handleAuthenticateEmployee } = useAuthenticateEmployee();

  return (
    <EmployeeContext.Provider
      value={{
        employee,
        setEmployee,
        handleLogoutEmployee,
        handleAuthenticateEmployee,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};

export const useEmployeeContext = () => {
  const employeeContext = useContext(EmployeeContext);

  if (!employeeContext) {
    throw new Error(
      "You may not use EmployeeContext outside of EmployeeProvider",
    );
  } else {
    return employeeContext;
  }
};
