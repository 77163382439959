import { Image } from "react-feather";
import { useSummaryViewContext } from "../../../../../contexts/SummaryViewContext";
import { LoadingSpinner } from "../../../../../ui/components/LoadingSpinner/LoadingSpinner";

type TicketLineItemProps = {
  name: string;
  quantity: number;
  status: string;
  isLastLineItem: boolean;
  onPeekClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => Promise<void>;
  onLineItemClick: () => void;
  showPeekIcon: boolean;
  showLoadingIcon: boolean;
};

export const TicketLineItem = ({
  name,
  quantity,
  status,
  isLastLineItem,
  onPeekClick,
  onLineItemClick,
  showPeekIcon,
  showLoadingIcon,
}: TicketLineItemProps) => {
  const ticketLineItemBackgroundColor = getTicketLineItemColor(status);
  const { itemName, disableKDSClick } = useSummaryViewContext();

  const handleClick = () => {
    return disableKDSClick ? () => {} : onLineItemClick();
  };

  return (
    <div
      className={`${
        itemName === name
          ? "border-dashed border-4 border-[#111]"
          : "border-gray-300"
      } relative font-bold text-xl py-4 flex w-full flex-row items-center justify-between transition duration-700 ease-in-out ${ticketLineItemBackgroundColor} ${
        isLastLineItem ? "mb-4 rounded-b-lg" : ""
      }`}
      onClick={handleClick}
    >
      <div className={`px-3 flex w-full flex-row items-center justify-between`}>
        <div className="flex flex-row items-center justify-center p-1">
          {showLoadingIcon ? (
            <div className="py-[6px] pl-[4.75px] pr-4">
              <LoadingSpinner size={6} borderWidth={4} />
            </div>
          ) : (
            <div className="py-[1px] px-[10px] bg-gray-100 border border-gray-500 flex flex-row items-center justify-center mr-3 rounded-md text-xl font-extrabold">
              {quantity}
            </div>
          )}

          <div className="overflow-hidden break-all">{name}</div>
        </div>
        {showPeekIcon && !disableKDSClick ? (
          <div className="mr-1">
            <button
              onClick={(e) => {
                onPeekClick(e);
              }}
              className="w-full h-full outline outline-offset-0 outline-2 outline-gray-300 bg-white rounded-lg text-gray-800 p-2"
            >
              <Image size={"32"} />
            </button>
          </div>
        ) : null}
      </div>
      {!isLastLineItem ? (
        <div
          className={`${
            itemName === name ? "-bottom-8" : "-bottom-6"
          } w-full pl-4 text-center absolute z-0 bg-white text-gray-700 font-inter text-base font-bold border-t border-gray-300`}
        >
          Continues...
        </div>
      ) : null}
    </div>
  );
};

const getTicketLineItemColor = (status: string) => {
  switch (status) {
    case "RECEIVED":
      return "bg-green-200";
    case "READY":
      return "bg-blue-200";
    default:
      return "bg-white";
  }
};
