import { Dispatch, SetStateAction, useState } from "react";
import { X } from "react-feather";
import { usePeekContext } from "../../contexts/PeekContext";
import { KdsTicket, useGetPeekMediaQuery } from "../../graphql/generated";
import { useSegment } from "../../utils/hooks/useSegment";
import { PeekImageView } from "./PeekImageView";

type PeekParams = {
  setShowPeek: Dispatch<SetStateAction<boolean>>;
  ticket: KdsTicket;
  updateLineItemStatus: (
    ticket_line_item_id: number,
    status: string,
    printChit: boolean,
    markReceived: boolean,
    targetId: number,
    targetType: string,
  ) => Promise<void>;
};

const PeekV2 = ({ setShowPeek, ticket }: PeekParams) => {
  const { deselectLineItem: deselectPeekItem } = usePeekContext();
  const { track } = useSegment();

  const line_item = ticket?.line_items?.[0];
  const { data: imageData, loading: imageLoading } = useGetPeekMediaQuery({
    variables: {
      menu_item_id: line_item?.target_id, //TODO: Rename menu_item_id to store_order_line_item_id once refactor launches
      is_peek_v2: true,
    },
  });
  const buildGridImgLink = imageData?.peek_media?.build_grid_img_link;
  const finalPlatedImgLink = imageData?.peek_media?.final_plated_img_link;
  const fallBackImgLink = imageData?.peek_media?.fallback_image_link;

  const [spanishMode, setSpanishMode] = useState(false);

  return (
    <div className="absolute h-full z-50 top-0 py-4">
      <div className="flex flex-col w-full h-full bg-black rounded-xl border-2 border-gray-700 p-4 overflow-hidden">
        <div className="flex align-top flex-row flex-grow items-center">
          <button
            className="flex h-[75px] items-center rounded-xl border-2 bg-black border-gray-700 px-2 py-2 text-2xl text-gray-200 font-inter font-semibold"
            onClick={() => {
              track({
                event: `Peek Close`,
                properties: {
                  store_order_id: ticket.id,
                  line_item_id: line_item?.id,
                },
              });
              deselectPeekItem();
              setShowPeek(false);
            }}
          >
            <X color="#CFCFCF" size={48} />
          </button>

          <h3 className="h-full font-bold text-[36px] font-inter text-gray-200 ml-4 leading-[46px]">
            Peek
          </h3>
        </div>

        <div className="flex bg-black w-[calc(100vw-5.2rem)] pt-4 h-[100%] justify-between">
          <div className="w-5/12">
            {!imageLoading && (
              <PeekImageView
                buildGridImage={buildGridImgLink || undefined}
                spanishMode={spanishMode}
                fallbackImage={fallBackImgLink || undefined}
              />
            )}
          </div>
          <div className="w-5/12">
            {!imageLoading && (
              <PeekImageView
                finalPlatedImage={finalPlatedImgLink || undefined}
                packagingImage={fallBackImgLink || undefined}
                spanishMode={spanishMode}
                fallbackImage={fallBackImgLink || undefined}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeekV2;
