import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useSegment } from "../../../utils/hooks/useSegment";

const KDS_AUTHENTICATE_EMPLOYEE = gql`
  mutation KDS_AUTHENTICATE_EMPLOYEE(
    $pin_code: String
    $location_id: Int
    $device: String
    $session_roles: [String]
  ) {
    authenticate_employee(
      pin_code: $pin_code
      location_id: $location_id
      device: $device
      session_roles: $session_roles
    ) {
      id
      first_name
      last_name
      email
      employee_login_id
    }
  }
`;

export type AuthenticateEmployeeProps = {
  pinCode: string;
  locationId?: number;
  device: string;
  sessionRoles: ("EXPO" | "COOK")[];
};

export const useAuthenticateEmployee = () => {
  const [authenticateEmployee, { loading, error }] = useMutation(
    KDS_AUTHENTICATE_EMPLOYEE,
  );
  const { identify, track } = useSegment();

  const handleAuthenticateEmployee = useCallback(
    async ({
      pinCode,
      locationId,
      device,
      sessionRoles,
    }: AuthenticateEmployeeProps) => {
      if (!locationId) {
        return null;
      }

      const authenticateRes = await authenticateEmployee({
        variables: {
          pin_code: pinCode,
          location_id: locationId,
          device,
          session_roles: sessionRoles,
        },
      });
      const cookData = authenticateRes?.data?.authenticate_employee;
      return cookData;
    },
    [authenticateEmployee, identify, track],
  );

  return { handleAuthenticateEmployee, loading, error };
};
