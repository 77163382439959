import { ChevronLeft } from "react-feather";
import { useLocation } from "react-router-dom";
import { useLocationContext } from "../../contexts/LocationContext";
import { useLocations } from "../KitchenDisplaySystem/hooks/useLocations";

export const Settings = () => {
  const { slug: currentLocation, updateLocation } = useLocationContext();
  const { data: locationsData } = useLocations();
  const routeLocation = useLocation();

  const handleLocationChange = (event: any) => {
    const { value } = event.target;
    updateLocation(value);
  };

  const locationRedirect = (location: string) => {
    const params = new URLSearchParams(routeLocation.search);
    params.set("location", location);
    window.location.replace(window.location.origin + "?" + params);
    updateLocation(location);
  };

  return (
    <div>
      <div className="max-w-sm mx-auto pt-20">
        <h1 className="text-xl font-lfg pb-2 text-white">Store Location:</h1>
        <div className="flex items-center">
          <button
            className="bg-gray-800 text-white m-1 p-1 rounded"
            onClick={() => locationRedirect(currentLocation)}
          >
            <ChevronLeft />
          </button>
          <select
            id="locationId"
            name="locationId"
            autoComplete="locationId"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-xl border-gray-300 rounded-md bg-gray-800 text-white p-1"
            value={currentLocation}
            onChange={handleLocationChange}
          >
            {locationsData?.locations
              .filter((location: any) => location.is_active)
              .map((location: any, index: number) => (
                <option key={`location-${index}`} value={location.slug}>
                  {location.name}
                </option>
              ))}
          </select>
        </div>
      </div>
    </div>
  );
};
