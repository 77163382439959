import { gql, useQuery } from "@apollo/client";

export const COOK_EMPLOYEE_QUERY = gql`
  query COOK_EMPLOYEE_QUERY($cook_id: Int) {
    cook(cook_id: $cook_id) {
      id
      first_name
      last_name
      email
      completed_item_count
    }
  }
`;

export const useCookEmployee = (cookId: number) => {
  const { data, error, loading, refetch } = useQuery(COOK_EMPLOYEE_QUERY, {
    variables: { cook_id: cookId },
  });

  return { data, error, loading, refetch };
};
