import { Airplay } from "react-feather";

export const TicketScannedHeader = () => {
  return (
    <div
      className={`py-[7.5px] px-4 flex w-full h-full items-center justify-between rounded-t-lg transition duration-700 ease-in-out bg-blue-600`}
    >
      <div className="flex flex-col items-center w-full text-white font-medium font-inter text-xl">
        <Airplay
          color="white"
          size={32}
          className={`mt-[1px]`}
          strokeWidth={3}
        />
        {"Scan Successful"}
      </div>
    </div>
  );
};
