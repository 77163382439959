import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useEmployeeContext } from "../../../contexts/EmployeeContext";
import { useSegment } from "../../../utils/hooks/useSegment";

const KDS_LOGOUT_EMPLOYEE = gql`
  mutation KDS_LOGOUT_EMPLOYEE(
    $employee_id: Int
    $location_id: Int
    $device: String
    $employee_login_id: Int
  ) {
    logout_employee(
      employee_id: $employee_id
      location_id: $location_id
      device: $device
      employee_login_id: $employee_login_id
    ) {
      success
    }
  }
`;

type LogoutEmployeeProps = {
  employeeId?: number;
  employeeLoginId?: number;
  locationId?: number;
  device: string;
};

export const useLogoutEmployee = () => {
  const [logoutEmployee, { loading, error }] = useMutation(KDS_LOGOUT_EMPLOYEE);
  const { setEmployee } = useEmployeeContext();
  const { track } = useSegment();

  const handleLogoutEmployee = useCallback(
    async ({
      employeeId,
      employeeLoginId,
      device,
      locationId,
    }: LogoutEmployeeProps) => {
      const variables = {
        employee_id: employeeId,
        location_id: locationId,
        device,
        employee_login_id: employeeLoginId,
      };
      const logoutRes = await logoutEmployee({
        variables,
      });

      track({ event: "Logout Employee", properties: { employee: variables } });

      // Set the employee to an empty object
      setEmployee({});

      return logoutRes;
    },
    [track, logoutEmployee, setEmployee],
  );

  return { handleLogoutEmployee, loading, error };
};
