import { useEffect, useState } from "react";
import { NumberPad } from "./NumberPad";
import { PinPlaceholders } from "./PinPlaceholders";
import { useCurrentCookContext } from "../../../contexts/CurrentCookContext";

export const CookLoginModal = () => {
  const [pinCode, setPinCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { handleCookAuthentication } = useCurrentCookContext();

  const onNumberPadButtonClick = (value: any) => {
    if (value === "CLEAR") {
      setPinCode("");
    } else if (value === "BACKSPACE") {
      setPinCode(`${pinCode.slice(0, -1)}`);
    } else {
      setPinCode(`${pinCode.concat(value)}`);
    }

    setErrorMessage("");
  };

  useEffect(() => {
    if (pinCode.length >= 6) {
      handleCookAuthentication(pinCode).then((result) => {
        const { status } = result;

        if (status) {
          setErrorMessage("");
        } else {
          setErrorMessage("Wrong Pin. Please try again");
        }
      });
    }
  }, [pinCode]);

  return (
    <>
      <div className="p-4 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-20 outline-none focus:outline-none">
        <div
          className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none w-full text-black py-10 "
          style={{ maxWidth: 500 }}
        >
          <h1 className="text-4xl text-center tracking-tight font-lfg-bold">
            Enter PIN Code
          </h1>
          <PinPlaceholders numberOfPins={6} filledPins={pinCode.length} />
          <div className="flex justify-center">
            <div className="w-4/6">
              <NumberPad onNumberPadButtonClick={onNumberPadButtonClick} />
              <div className="h-4">
                {errorMessage.length > 0 && (
                  <p className="text-red-500">{errorMessage}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-10 bg-black"></div>
    </>
  );
};
