import { useAlertWidgetTemporaryContext } from "../../../../../contexts/AlertWidgetTemporaryContext";
import { usePrepStationContext } from "../../../../../contexts/PrepStationContext";
import {
  KdsTicketLineItem,
  KdsTicketSubLineItem,
  useUpdateTicketSubLineItemStatusMutation,
} from "../../../../../graphql/generated";
import { ErrorAlertWidget } from "../../v2/AlertWidget/ErrorAlertWidget";
import { TicketSubLineItem } from "./TicketSubLineItem";

type TicketSubLineItemControllerProps = {
  ticketSubLineItem: KdsTicketSubLineItem;
  isLastLineItem: boolean;
  lineItemNumber: number;
  lineItemsTotalCount: number;
  parentLineItem: KdsTicketLineItem;
  updateParentStatus: (
    ticket_line_item_id: number,
    status: string,
    printChit: boolean,
    markReceived: boolean,
    targetId: number,
    targetType: string,
  ) => Promise<void>;
  blockSubLineItemClick: boolean;
  lineItemUpdateLoading: boolean;
};

export const TicketSubLineItemController = ({
  ticketSubLineItem,
  isLastLineItem,
  lineItemNumber,
  lineItemsTotalCount,
  parentLineItem,
  updateParentStatus,
  blockSubLineItemClick,
  lineItemUpdateLoading,
}: TicketSubLineItemControllerProps) => {
  const name = ticketSubLineItem?.name ?? "";
  const quantity = ticketSubLineItem?.quantity ?? 1;
  const isRemovalModifier = name.toLowerCase().startsWith("no");
  const itemName = isRemovalModifier ? name.toUpperCase() : name;

  const { setIsAlertWidgetTemporaryVisible, setTemporaryAlertMessage } =
    useAlertWidgetTemporaryContext();
  const { currentPrepStationId } = usePrepStationContext();

  const [updateSubLineItemStatusMutation] =
    useUpdateTicketSubLineItemStatusMutation();

  const onSubLineItemClick = async () => {
    if (lineItemUpdateLoading) return;
    if (blockSubLineItemClick) {
      setIsAlertWidgetTemporaryVisible(true);
      setTemporaryAlertMessage(
        <ErrorAlertWidget
          message={"Unready the order before marking items incomplete"}
        />,
      );
      setTimeout(() => {
        setTemporaryAlertMessage("");
        setIsAlertWidgetTemporaryVisible(false);
      }, 3000);
    } else if (ticketSubLineItem?.mark_received) {
      if (
        parentLineItem?.status === "READY" ||
        parentLineItem?.status === "RECEIVED"
      ) {
        if (ticketSubLineItem?.status === "CREATED") {
          updateSubLineItemStatusMutation({
            variables: {
              id: ticketSubLineItem?.id ?? 0,
              status: "RECEIVED",
              printChit: ticketSubLineItem?.print_chit ?? false,
              prepStationId: currentPrepStationId,
            },
          });
        } else {
          updateSubLineItemStatusMutation({
            variables: {
              id: ticketSubLineItem?.id ?? 0,
              status: "CREATED",
              printChit: ticketSubLineItem?.print_chit ?? false,
              prepStationId: currentPrepStationId,
            },
          });
          updateParentStatus(
            parentLineItem?.id ?? 0,
            "RECEIVED",
            parentLineItem?.print_chit ?? true,
            parentLineItem?.mark_received ?? true,
            parentLineItem?.target_id ?? 0,
            parentLineItem?.target_type ?? "",
          );
        }
      } else {
        updateParentStatus(
          parentLineItem?.id ?? 0,
          parentLineItem?.status ?? "",
          parentLineItem?.print_chit ?? true,
          parentLineItem?.mark_received ?? true,
          parentLineItem?.target_id ?? 0,
          parentLineItem?.target_type ?? "",
        );
      }
    } else {
      updateParentStatus(
        parentLineItem?.id ?? 0,
        parentLineItem?.status ?? "",
        parentLineItem?.print_chit ?? true,
        parentLineItem?.mark_received ?? true,
        parentLineItem?.target_id ?? 0,
        parentLineItem?.target_type ?? "",
      );
    }
  };
  return (
    <TicketSubLineItem
      name={itemName}
      quantity={quantity}
      status={ticketSubLineItem?.status ?? ""}
      isMarkReceivable={ticketSubLineItem?.mark_received ?? false}
      isLastLineItem={isLastLineItem}
      lineItemNumber={lineItemNumber}
      lineItemsTotalCount={lineItemsTotalCount}
      parentStatus={parentLineItem?.status ?? ""}
      onSubLineItemClick={onSubLineItemClick}
    />
  );
};
