import { AlertTriangle } from "react-feather";

type ErrorAlertWidgetProps = {
  message: string;
};

export const ErrorAlertWidget = ({ message }: ErrorAlertWidgetProps) => {
  return (
    <>
      <div className={"flex flex-row items-center"}>
        <AlertTriangle
          className="mr-3 outline-offset-4 rounded-sm"
          color="#FE4747"
          size={28}
        />
        <p>{message}</p>
      </div>
    </>
  );
};
