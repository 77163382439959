import { Minus, Plus } from "react-feather";

export type TagProps = { type: string; iconColor: string; tagStyling: string };

export const Tag = ({ type, iconColor, tagStyling }: TagProps) => {
  let Icon = Plus;

  switch (type) {
    case "plus":
      Icon = Plus;
      break;
    case "minus":
      Icon = Minus;
      break;
  }

  return (
    <Icon
      className={`outline outline-2 ${tagStyling} outline-offset-4 rounded-sm`}
      color={iconColor}
      size={16}
      strokeWidth={5}
    />
  );
};
