import { createContext, useContext, useState } from "react";

export type SummaryViewContextType = {
  isEnabled: boolean;
  setIsEnabled: (isEnabled: boolean) => void;
  itemName: string;
  setItemName: (itemId: string) => void;
  disableKDSClick: boolean;
};

export const SummaryViewContext = createContext<
  SummaryViewContextType | undefined
>(undefined);

export const SummaryViewContextProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [itemName, setItemName] = useState<string>("");
  const disableKDSClick = isEnabled;

  return (
    <SummaryViewContext.Provider
      value={{
        isEnabled,
        setIsEnabled,
        itemName,
        setItemName,
        disableKDSClick,
      }}
    >
      {children}
    </SummaryViewContext.Provider>
  );
};

export const useSummaryViewContext = () => {
  const context = useContext(SummaryViewContext);
  if (!context) {
    throw new Error(
      "You may not use SummaryViewContext outside of SummaryViewContextProvider",
    );
  } else {
    return context;
  }
};
