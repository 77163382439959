import { useState } from "react";
import { CornerUpLeft } from "react-feather";
import { useEmployeeContext } from "../../../../../contexts/EmployeeContext";
import { useLocationContext } from "../../../../../contexts/LocationContext";
import { usePrepStationContext } from "../../../../../contexts/PrepStationContext";
import { useScheduledOrdersContext } from "../../../../../contexts/ScheduledOrdersContext";
import { LoadingSpinner } from "../../../../../ui/components/LoadingSpinner/LoadingSpinner";
import { formatTime } from "../../../../../utils/formatTime";
import { useSegment } from "../../../../../utils/hooks/useSegment";

type TicketScheduledHeaderProps = {
  name?: string;
  pickupTime: Date;
  status: string;
  store_order_id: number;
};

export const TicketScheduledHeader = ({
  name,
  pickupTime,
  status,
  store_order_id,
}: TicketScheduledHeaderProps) => {
  const eta = formatTime(pickupTime);
  const { track } = useSegment();
  const { currentPrepStationId } = usePrepStationContext();

  const { slug: currentLocation } = useLocationContext();
  const { employee } = useEmployeeContext();
  const { doMoveScheduledOrderIntoCooking } = useScheduledOrdersContext();

  const [showCheckMark, setShowCheckMark] = useState(false);
  const [animateCheckMark, setAnimateCheckMark] = useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  const handleStartCookingClick = () => {
    if (showCheckMark || showLoadingSpinner) {
      return;
    }

    track({
      event: "Start Cooking Scheduled Order",
      properties: {
        currentPrepStationId,
        currentLocation,
        employee,
        store_order_id,
      },
    });

    setShowCheckMark(true);
    setTimeout(() => {
      setAnimateCheckMark(true);
    }, 50);
    setTimeout(() => {
      setShowCheckMark(false);
      setAnimateCheckMark(false);
      setShowLoadingSpinner(true);
    }, 800);
    setTimeout(async () => {
      doMoveScheduledOrderIntoCooking(store_order_id);
    }, 250);
  };

  return (
    <div
      className={
        "py-3 px-4 w-full h-full flex  flex-col items-end justify-between rounded-t-lg bg-gray-800 text-white"
      }
    >
      <div
        className={"mb-2 py-2 flex w-full h-full items-center justify-between "}
      >
        {showLoadingSpinner ? (
          <div className="my-[2px] flex flex-col items-center w-full">
            <LoadingSpinner size={6} borderWidth={4} />
          </div>
        ) : (
          <>
            {showCheckMark ? (
              <div className="mb-[1px] flex flex-col items-center w-full">
                <CornerUpLeft
                  color="white"
                  size={26}
                  className={` ${
                    animateCheckMark ? "opacity-100" : "opacity-0"
                  } transition-opacity duration-300 ease-in mt-[1px]`}
                  strokeWidth={3}
                />
              </div>
            ) : (
              <>
                <div className="font-medium font-inter text-xl">
                  {`${name}`}
                </div>
                <div className="font-medium font-inter text-xl">{`${eta}`}</div>
              </>
            )}
          </>
        )}
      </div>
      <div className="bg-gray-600 text-center py-[6px] font-bold w-full mb-4 rounded">
        <p className="text-[16px] text-white">Scheduled</p>
      </div>
      <button
        className={
          "mb-2 justify-center text-[22px]/[24px] font-semibold w-full rounded-full py-2 bg-white border-2 border-[#CFCFCF]"
        }
        onClick={handleStartCookingClick}
      >
        <p className="text-xl text-black">Start Cooking</p>
      </button>
    </div>
  );
};
