import * as Icon from "react-feather";
import { usePrepStationsWithOrders } from "../../hooks/usePrepStationWithOrders";
import { getNumOrdersAndItemsByPrepStation } from "../../utils/getNumOrdersAndItemsByPrepStation";
import { NoMoreItemsArrow } from "./NoMoreItemsArrow";
import { useLocationContext } from "../../../../contexts/LocationContext";
import { usePrepStationContext } from "../../../../contexts/PrepStationContext";

type NoMoreItemsViewProps = {
  cookingCount: number;
};

export const NoMoreItemsView = ({ cookingCount }: NoMoreItemsViewProps) => {
  const { id: currentLocationId, name: currentLocationName } =
    useLocationContext();
  const { isExpo } = usePrepStationContext();
  const { data: prepStationData } =
    usePrepStationsWithOrders(currentLocationId);

  const emptyStationMessage = () => {
    if (isExpo) {
      return "The kitchen has no outstanding items at the moment.";
    } else if (!itemsCooking()) {
      return "The kitchen has no outstanding items, feel free to logout from this station.";
    } else {
      return "These stations could use your help next, feel free to logout from this station.";
    }
  };

  const itemsCooking = () => {
    return (
      getNumOrdersAndItemsByPrepStation(prepStationData).filter(
        (station) => station.numItems > 0,
      ).length > 0
    );
  };
  return (
    <div className="relative my-4 flex h-32 w-full grow flex-col text-white">
      <div className="flex flex-none justify-center text-[64px] font-bold text-white">
        {isExpo
          ? `${currentLocationName || "This kitchen"} is up to speed`
          : "No more items at the moment"}
      </div>
      <div className="mb-16 flex flex-none justify-center text-[28px] font-medium text-gray-300">
        {emptyStationMessage()}
      </div>
      {isExpo || !itemsCooking() ? (
        <div className="flex justify-center">
          <Icon.Check height={236} width={164} />
        </div>
      ) : (
        <div className="flex w-full grow flex-col items-center justify-start gap-y-8 pb-6">
          {getNumOrdersAndItemsByPrepStation(prepStationData)
            .filter((station) => station.numItems > 0)
            .filter((station) => station.name !== "Expo")
            .sort((stationA, stationB) =>
              stationA.numItems > stationB.numItems ? -1 : 0,
            )
            .slice(0, 3)
            .map((station) => (
              <div
                className="flex h-16 max-h-[116px] w-full flex-1 grow flex-row items-center rounded-xl bg-gray-900"
                key={station.name}
              >
                <div className="font-inter grow px-5 text-[28px] font-medium text-white">
                  {station.name}
                </div>
                <div className="font-inter w-20 flex-none text-[28px] font-medium text-white">
                  {station.numItems}
                </div>
              </div>
            ))}
        </div>
      )}
      <div className="absolute right-0 top-6">
        <NoMoreItemsArrow />
      </div>
    </div>
  );
};
