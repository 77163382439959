import { createContext, useCallback, useContext, useState } from "react";

export type PeekContextType = {
  itemId: number | null;
  setItemId: (itemId: number | null) => void;
  orderId: any | null;
  selectLineItem: (itemId: number | null, order: any | null) => void;
  deselectLineItem: () => void;
  isDisplayed: boolean;
};

export const PeekContext = createContext<PeekContextType | undefined>(
  undefined,
);

export const PeekContextProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [itemId, setItemId] = useState<number | null>(null);
  const [orderId, setOrderId] = useState<any>(null);

  const selectLineItem = useCallback(
    (itemId: number | null, orderId: number | null) => {
      setItemId(itemId);
      setOrderId(orderId);
    },
    [setItemId],
  );

  const deselectLineItem = useCallback(() => {
    setItemId(null);
    setOrderId(null);
  }, [setItemId, setOrderId]);

  const isDisplayed = !!itemId && !!orderId;

  return (
    <PeekContext.Provider
      value={{
        itemId: itemId,
        setItemId,
        selectLineItem,
        deselectLineItem,
        orderId,
        isDisplayed,
      }}
    >
      {children}
    </PeekContext.Provider>
  );
};

export const usePeekContext = () => {
  const context = useContext(PeekContext);
  if (!context) {
    throw new Error(
      "You may not use PeekContext outside of PeekContextProvider",
    );
  } else {
    return context;
  }
};
