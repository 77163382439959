import { Check } from "react-feather";

export const ScheduledOrderToCookingAlertWidget = ({
  onCancel,
}: {
  onCancel?: () => void;
}) => {
  return (
    <>
      <div className={"flex flex-row items-center"}>
        <Check
          className="mr-4 outline outline-[3px] outline-lfg-green outline-offset-4 rounded-sm"
          color="#01A55C"
          size={17}
        />
        <p>Moved scheduled order into cooking</p>
        <button
          className={
            "ml-4 border-[2px] border-[#585858] w-fit rounded-full bg-white text-black text-[22px] px-8 py-2"
          }
          onClick={() => onCancel?.()}
        >
          Undo
        </button>
      </div>
    </>
  );
};
