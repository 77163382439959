import { useGetScheduledOrdersQuery } from "../../../graphql/generated";

export const useScheduledOrderNotifications = (prepStationId?: number) => {
  const { data, loading, error, startPolling, stopPolling } =
    useGetScheduledOrdersQuery({
      variables: {
        prepStationId: prepStationId || 0,
      },
      fetchPolicy: "network-only",
      skip: prepStationId === undefined,
      pollInterval: 1000 * 10,
    });

  return {
    data: data?.scheduled_order_notifications || [],
    loading,
    error,
    startPolling,
    stopPolling,
  };
};
