import { PropsWithChildren, createContext, useContext, useState } from "react";
import { KdsTicket } from "../graphql/generated";
import { SummaryViewItem } from "../types";

type DisplayContextType = {
  tickets: KdsTicket[];
  setTickets: (tickets: KdsTicket[]) => void;
  positionX: number;
  setPositionX: (positionX: number) => void;
  groupedItems: GroupedLineItems | null;
  setGroupedItems: (groupedItems: GroupedLineItems | null) => void;
};

export type GroupedLineItems = Map<string, SummaryViewItem>;

export const DisplayContext = createContext<DisplayContextType>({
  tickets: [],
  setTickets: () => {},
  positionX: 0,
  setPositionX: () => {},
  groupedItems: null,
  setGroupedItems: () => {},
});

export const DisplayContextProvider = ({
  children,
}: PropsWithChildren<React.ReactElement | JSX.Element | any>) => {
  const [positionX, setPositionX] = useState(0);
  const [groupedItems, setGroupedItems] = useState<GroupedLineItems | null>(
    new Map(),
  );
  const [tickets, setTickets] = useState<KdsTicket[]>([]);

  return (
    <DisplayContext.Provider
      value={{
        tickets,
        setTickets,
        positionX,
        setPositionX,
        groupedItems,
        setGroupedItems,
      }}
    >
      {children}
    </DisplayContext.Provider>
  );
};

export const useDisplayContext = () => {
  const displayContext = useContext(DisplayContext);
  if (!displayContext) {
    throw new Error(
      "You may not use DisplayContext outside of DisplayContextProvider",
    );
  }

  return displayContext;
};
