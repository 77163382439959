import { Check } from "react-feather";

export const ViewedScheduledAlertWidget = () => {
  return (
    <>
      <div className={"flex flex-row items-center"}>
        <Check
          className="mr-4 outline outline-[3px] outline-lfg-green outline-offset-4 rounded-sm"
          color="#01A55C"
          size={17}
        />
        <p>Scheduled order acknowledged</p>
      </div>
    </>
  );
};
