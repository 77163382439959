import React, { PropsWithChildren, useEffect, useState } from "react";
import { useAlertWidgetTemporaryContext } from "../../../../contexts/AlertWidgetTemporaryContext";
import { useCurrentCookContext } from "../../../../contexts/CurrentCookContext";
import { useDisplayContext } from "../../../../contexts/DisplayContext";
import { useLocationContext } from "../../../../contexts/LocationContext";
import { usePrepStationContext } from "../../../../contexts/PrepStationContext";
import {
  KdsTicket,
  useGetScannedLineItemQuery,
  useGetTicketsQuery,
  useUpdateTicketLineItemStatusMutation,
} from "../../../../graphql/generated";
import PeekV2 from "../../../Peek/PeekV2";
import { TicketHeaderController } from "./TicketHeader/TicketHeaderController";
import { TicketLineItemController } from "./TicketLineItem/TicketLineItemController";
import { TicketSubLineItemController } from "./TicketSubLineItem/TicketSubLineItemController";

type TicketProps = { ticket: KdsTicket; columns: number; ticketsRef: any };
const POLL_INTERVAL = 1000 * 60 * 10;
const TIME_OUT_DURATION = 2000;

const RowContainer = ({
  children,
  columnWidthClass,
}: PropsWithChildren<{
  columnWidthClass: string;
}>) => {
  return (
    <div
      className={`${columnWidthClass} px-2 justify-center items-center flex flex-col`}
    >
      {children}
    </div>
  );
};

export const Ticket = ({ ticket, columns, ticketsRef }: TicketProps) => {
  const columnWidthClass = getColumnWidthClass(columns);

  const [showPeek, setShowPeek] = useState(false);
  const [peekId, setPeekId] = useState(0);
  const [showLoadingIconForId, setShowLoadingIconForId] = useState(0);

  const [peekTicket, setPeekTicket] = useState<KdsTicket>(ticket);
  const { isExpo, currentPrepStationId } = usePrepStationContext();
  const { currentCook } = useCurrentCookContext();
  const { setPositionX } = useDisplayContext();

  const { id: currentLocationId } = useLocationContext();
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  const [showScannedHeader, setShowScannedHeader] = useState(false);
  const [scannedTicketId, setScannedTicketId] = useState<number | null>();
  const {
    setIsAlertWidgetTemporaryVisible,
    setTemporaryAlertMessage,
    setSoundEffectSource,
  } = useAlertWidgetTemporaryContext();

  const { data } = useGetScannedLineItemQuery({
    variables: {
      location_id: currentLocationId || 0,
    },
    pollInterval: POLL_INTERVAL,
  });

  let scanned_item_event_id = data?.scanned_line_item
    ? data.scanned_line_item[0]?.event_id
    : null;
  let scanned_item_store_order_id = data?.scanned_line_item
    ? data.scanned_line_item[0]?.store_order_id
    : null;
  let scanned_item_store_order_line_item_id = data?.scanned_line_item
    ? data.scanned_line_item[0]?.store_order_line_item_id
    : null;

  const lineItemsLength = ticket.line_items?.length ?? 0;
  const firstLineItem = ticket.line_items?.[0];

  useEffect(() => {
    if (
      scanned_item_store_order_id &&
      scanned_item_store_order_line_item_id &&
      isExpo &&
      ticket.id == scanned_item_store_order_id
    ) {
      setScannedTicketId(scanned_item_store_order_id);
      if (timerId) clearTimeout(timerId);

      const scannedLineItem = getTicketLineItemBySOLI(
        ticket,
        scanned_item_store_order_line_item_id,
      );

      const targetTicketElement = document.getElementById(
        String(scanned_item_store_order_id),
      );
      if (targetTicketElement) {
        ticketsRef.current.scrollTo({
          left: targetTicketElement.offsetLeft - 25,
          behavior: "smooth",
        });
        setPositionX(targetTicketElement.offsetLeft - 25);
      }
      updateLineItemStatus(
        scannedLineItem?.id ?? 0,
        "READY",
        false,
        true,
        scannedLineItem?.target_id ?? 0,
        scannedLineItem?.target_type ?? "",
      );

      setShowScannedHeader(true);
      const received_items = getCountTicketItemsReceived(ticket) ?? 0;
      const current_item_buffer =
        scannedLineItem?.status === "RECEIVED" ? 0 : 1;
      setIsAlertWidgetTemporaryVisible(true);
      setTemporaryAlertMessage(
        `✅ Item ${
          received_items + current_item_buffer
        }/${lineItemsLength} for Ticket #${ticket.ticket_counter}`,
      );
      if (received_items + current_item_buffer === lineItemsLength) {
        setSoundEffectSource("/order-completed.mp3");
      }

      analytics.track("Scanned Line Item Mark Received", {
        store_order_id: scanned_item_store_order_id,
        store_order_line_item_id: scanned_item_store_order_line_item_id,
        prep_station_id: currentPrepStationId,
        current_cook_id: currentCook?.id,
      });

      let id = setTimeout(() => {
        setShowScannedHeader(false);
        setScannedTicketId(null);
        setTemporaryAlertMessage("");
        setIsAlertWidgetTemporaryVisible(false);
      }, TIME_OUT_DURATION);
      setTimerId(id);
    }
  }, [
    scanned_item_event_id,
    scanned_item_store_order_id,
    scanned_item_store_order_line_item_id,
    isExpo,
    lineItemsLength,
  ]);

  const [updateLineItemStatusMutation, { loading: lineItemUpdateLoading }] =
    useUpdateTicketLineItemStatusMutation();

  const { refetch } = useGetTicketsQuery({
    variables: {
      locationId: currentLocationId,
    },
  });

  const updateLineItemStatus = async (
    ticket_line_item_id: number,
    status: string,
    printChit: boolean,
    markReceived: boolean,
    targetId: number,
    targetType: string,
  ) => {
    // If the line item status update loading or has mark_received set to false, then don't allow state change
    if (lineItemUpdateLoading || !markReceived) return;

    const updatedStatus = nextStatus(status, isExpo);
    setShowLoadingIconForId(ticket_line_item_id);
    await updateLineItemStatusMutation({
      variables: {
        id: ticket_line_item_id,
        status: updatedStatus,
        printChit: printChit,
        prepStationId: currentPrepStationId,
        cookId: currentCook?.id ?? 0,
        targetId: targetId,
        targetType: targetType,
      },
    });
    // HACK: Need to pass back subline items that can be mark received
    // Otherwise cache will not invalidate, refetching for now
    refetch();
    analytics.track("KDS Ticket Line Item Status Updated", {
      kds_ticket_line_item_id: ticket_line_item_id,
      store_order_id: ticket.id,
      prep_station_id: currentPrepStationId,
      current_cook_id: currentCook?.id,
      status: updatedStatus,
    });
  };

  useEffect(() => {
    if (showPeek) {
      // Filter the line_items array based on the peek_id
      const filteredLineItems = ticket.line_items?.filter(
        (item) => item?.id === peekId,
      );

      // Create a new object with the filtered line_items
      const filteredObject = { ...ticket, line_items: filteredLineItems };

      setPeekTicket(filteredObject);
    }
  }, [showPeek, peekId]);

  if (showPeek) {
    return (
      <PeekV2
        setShowPeek={setShowPeek}
        ticket={peekTicket}
        updateLineItemStatus={updateLineItemStatus}
      />
    );
  }

  return (
    <>
      {/* Render together: ticket header + the first line item + the first subline item  */}
      <RowContainer columnWidthClass={columnWidthClass}>
        <TicketHeaderController
          ticket={ticket}
          lineItemStatusUpdateLoading={lineItemUpdateLoading}
          showScannedHeader={showScannedHeader && ticket.id === scannedTicketId}
        />
        {firstLineItem ? (
          <TicketLineItemController
            ticketLineItem={firstLineItem}
            isLastLineItem={
              // only one line item and no subline items
              lineItemsLength === 1 && !firstLineItem.sub_line_items?.length
            }
            updateLineItemStatus={updateLineItemStatus}
            ticketStatus={ticket.status ?? ""}
            setShowPeek={setShowPeek}
            setPeekId={setPeekId}
            showPeekIcon={true}
            lineItemUpdateLoading={lineItemUpdateLoading}
            showLoadingIcon={
              lineItemUpdateLoading && firstLineItem.id == showLoadingIconForId
            }
          />
        ) : null}
        {firstLineItem && firstLineItem.sub_line_items?.[0] ? (
          <TicketSubLineItemController
            ticketSubLineItem={firstLineItem.sub_line_items[0]}
            isLastLineItem={
              // only one line item and one subline item
              lineItemsLength === 1 && firstLineItem.sub_line_items.length === 1
            }
            lineItemNumber={1}
            lineItemsTotalCount={firstLineItem.sub_line_items.length}
            parentLineItem={firstLineItem}
            updateParentStatus={updateLineItemStatus}
            blockSubLineItemClick={ticket.status == "READY"}
            lineItemUpdateLoading={
              lineItemUpdateLoading || ticket.status == "SCHEDULED"
            }
          />
        ) : null}
      </RowContainer>

      {/* Render separately: remaining subline items for the first ticket */}
      {firstLineItem?.sub_line_items?.map((sub_line_item, index) => {
        if (!sub_line_item || index === 0) return null; // skip first, already rendered
        return (
          <RowContainer
            key={sub_line_item.id}
            columnWidthClass={columnWidthClass}
          >
            <TicketSubLineItemController
              ticketSubLineItem={sub_line_item}
              isLastLineItem={
                // only one line item and this is the last subline item
                lineItemsLength === 1 &&
                firstLineItem.sub_line_items?.length === index + 1
              }
              lineItemNumber={index + 1}
              lineItemsTotalCount={firstLineItem.sub_line_items?.length ?? 0}
              parentLineItem={firstLineItem}
              updateParentStatus={updateLineItemStatus}
              blockSubLineItemClick={ticket.status == "READY"}
              lineItemUpdateLoading={
                lineItemUpdateLoading || ticket.status == "SCHEDULED"
              }
            />
          </RowContainer>
        );
      })}

      {/* Render the remaining line items */}
      {ticket.line_items?.map((line_item, index) => {
        if (!line_item || index === 0) return null; // skip first, already rendered

        const firstSubLineItem = line_item.sub_line_items?.[0];
        const sublineItemsLength = line_item.sub_line_items?.length ?? 0;

        return (
          <React.Fragment key={line_item.id}>
            {/* Render each line item with the first subline item */}
            <RowContainer columnWidthClass={columnWidthClass}>
              <TicketLineItemController
                ticketLineItem={line_item}
                isLastLineItem={
                  // this is the last line item and no subline items
                  lineItemsLength === index + 1 && !sublineItemsLength
                }
                updateLineItemStatus={updateLineItemStatus}
                ticketStatus={ticket.status ?? ""}
                setShowPeek={setShowPeek}
                setPeekId={setPeekId}
                showPeekIcon={true}
                lineItemUpdateLoading={lineItemUpdateLoading}
                showLoadingIcon={
                  lineItemUpdateLoading && line_item.id == showLoadingIconForId
                }
              />
              {firstSubLineItem ? (
                <TicketSubLineItemController
                  ticketSubLineItem={firstSubLineItem}
                  isLastLineItem={
                    // this is the last line item and this is the last subline item
                    lineItemsLength === index + 1 && sublineItemsLength === 1
                  }
                  lineItemNumber={1}
                  lineItemsTotalCount={sublineItemsLength}
                  parentLineItem={line_item}
                  updateParentStatus={updateLineItemStatus}
                  blockSubLineItemClick={ticket.status == "READY"}
                  lineItemUpdateLoading={
                    lineItemUpdateLoading || ticket.status == "SCHEDULED"
                  }
                />
              ) : null}
            </RowContainer>

            {/* Render remaining subline items separately */}
            {line_item.sub_line_items?.map((sub_line_item, sli_index) => {
              if (!sub_line_item || sli_index === 0) return null; // skip first, already rendered

              const sublineItemsLength = line_item.sub_line_items?.length ?? 0;

              return (
                <RowContainer
                  key={sub_line_item.id}
                  columnWidthClass={columnWidthClass}
                >
                  <TicketSubLineItemController
                    ticketSubLineItem={sub_line_item}
                    isLastLineItem={
                      // this is the last line item and this is the last subline item
                      lineItemsLength === index + 1 &&
                      sublineItemsLength === sli_index + 1
                    }
                    lineItemNumber={sli_index + 1}
                    lineItemsTotalCount={sublineItemsLength}
                    parentLineItem={line_item}
                    updateParentStatus={updateLineItemStatus}
                    blockSubLineItemClick={ticket.status == "READY"}
                    lineItemUpdateLoading={
                      lineItemUpdateLoading || ticket.status == "SCHEDULED"
                    }
                  />
                </RowContainer>
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
};

const getColumnWidthClass = (columnCount: number) => {
  if (columnCount === 1) {
    return "w-full";
  } else if (columnCount === 2) {
    return "w-1/2";
  } else if (columnCount === 3) {
    return "w-1/3";
  } else if (columnCount === 4) {
    return "w-1/4";
  }

  return "w-1/4";
};

const nextStatus = (status: string, isExpo: boolean) => {
  switch (status) {
    case "RECEIVED":
      return "CREATED";
    case "READY":
      return isExpo ? "RECEIVED" : "CREATED";
    case "CREATED":
      return "READY";
    default:
      return "RECEIVED";
  }
};

const getTicketLineItemBySOLI = (ticket: KdsTicket, id: number) => {
  const matchingLineItem = ticket.line_items?.filter(
    (lineItem) =>
      lineItem &&
      lineItem.target_type === "STORE_ORDER_LINE_ITEM" &&
      lineItem.target_id === id,
  );
  return matchingLineItem?.[0];
};

const getCountTicketItemsReceived = (ticket: KdsTicket) => {
  const received_line_items = ticket.line_items?.filter(
    (lineItem) => lineItem?.status === "RECEIVED",
  );

  return received_line_items?.length;
};
