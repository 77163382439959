type PinPlaceholdersV2Props = {
  numberOfPins: number;
  filledPins: number;
};

export const PinPlaceholdersV2 = ({
  numberOfPins,
  filledPins,
}: PinPlaceholdersV2Props) => {
  const defaultBgDolor = "bg-black";
  const filledBgColor = "bg-white";

  return (
    <div className="flex h-12 justify-center">
      {[...Array(filledPins)].map((_, index: number) => (
        <span
          className={`${
            index < filledPins ? filledBgColor : defaultBgDolor
          } mx-3 h-12 w-12 rounded-full`}
          key={index}
        ></span>
      ))}
    </div>
  );
};
