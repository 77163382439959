import { useAuth } from "@localkitchens/passwordless-auth";
import { PropsWithChildren } from "react";
import { StatsigProvider } from "statsig-react";
import { useLocationContext } from "./LocationContext";
import { usePrepStationContext } from "./PrepStationContext";

const KdsStatsigProvider = ({ children }: PropsWithChildren<{}>) => {
  const { user } = useAuth();
  const { slug: storeLocation } = useLocationContext();
  const { currentPrepStationId: prepStationId } = usePrepStationContext();

  return (
    <StatsigProvider
      sdkKey={process.env.REACT_APP_STATSIG_KDS_CLIENT_SECRET ?? ""}
      user={{
        userID: user?.email,
        custom: {
          provider: "kds",
          location: storeLocation ?? "",
          prepStationId: prepStationId ?? "",
        },
        customIDs: {
          anonymousID: analytics?.user?.().anonymousId(),
        },
      }}
      options={{
        environment: {
          tier: process.env.REACT_APP_STATSIG_ENVIRONMENT || "production",
        },
        overrideStableID: analytics?.user?.().anonymousId(),
      }}
      waitForInitialization={false}
    >
      {children}
    </StatsigProvider>
  );
};

export default KdsStatsigProvider;
