import { useAuth } from "@localkitchens/passwordless-auth";
import { PrepStationLoginArrow } from "./PrepStationLoginArrow";
import { usePrepStationContext } from "../../../../contexts/PrepStationContext";

type LoginStationPickerProps = {
  setShowLoginStationPicker: any;
};
export const LoginStationPicker = ({
  setShowLoginStationPicker,
}: LoginStationPickerProps) => {
  const { logout } = useAuth();
  const { prepStationData, handlePrepStationChange } = usePrepStationContext();
  const prepStations = prepStationData.location.prep_stations;

  return (
    <div className="absolute z-50 grid h-screen w-screen grid-cols-2 bg-gray-800">
      <div className="relative flex h-full items-center justify-center">
        <div className="w-[70%]">
          <div className="font-inter my-32 text-5xl font-bold leading-[48px] text-white">
            Which station would you like to open?
          </div>
          <div
            onClick={() => {
              logout({
                returnTo: window.location.origin,
              });
            }}
            className="font-inter my-32 flex h-[75px] w-[213px] items-center justify-center rounded-xl border-2 border-gray-600 bg-black text-[22px] font-semibold text-white"
          >
            Logout from KDS
          </div>
        </div>
        <div className="absolute right-4">
          <PrepStationLoginArrow />
        </div>
      </div>
      <div className=" flex flex-col items-center justify-center">
        <div className="text-large my-[98px] flex h-32 w-[80%] grow flex-col gap-y-8 overflow-y-scroll rounded-xl border-2 border-gray-700 bg-black p-8 text-white">
          {prepStations.map((prepStation: any) => {
            return (
              <div
                key={prepStation?.id}
                className="flex w-full items-center justify-between"
              >
                <div className="font-inter items-center text-[28px]">
                  {prepStation.name}
                </div>
                <button
                  onClick={() => {
                    handlePrepStationChange?.(prepStation);
                    setShowLoginStationPicker(false);
                  }}
                  className="text-whiteborder-2 h-[56px] w-[116px] rounded-[100px] border-2 border-gray-700 text-[22px]"
                >
                  Open
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
