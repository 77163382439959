type NewOrderAlertProps = {
  count: number;
  onAlertClick: () => void;
};

export const NewOrderAlert = ({ count, onAlertClick }: NewOrderAlertProps) => {
  return (
    <>
      <div
        className="absolute bottom-0 right-0 left-0 h-full w-full bg-blue-600 flex items-center justify-center flex-col z-20"
        onClick={onAlertClick}
      >
        <div className="rounded-full w-56 h-56 bg-white flex items-center justify-center flex-col text-blue-600">
          <p className="text-8xl font-medium mb-4">{count}</p>
          {count > 1 ? (
            <p className="text-3xl">tickets</p>
          ) : (
            <p className="text-3xl">ticket</p>
          )}
        </div>
        <div className="text-white text-3xl mt-5 mb-2 text-center">
          <p>{count > 1 ? <span>Need</span> : <span>Needs</span>} attention</p>
          <p className="text-xl mt-1">Tap anywhere to dismiss</p>
        </div>
      </div>
    </>
  );
};
