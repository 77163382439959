import { GroupedLineItems } from "../../../contexts/DisplayContext";
import { useSummaryViewContext } from "../../../contexts/SummaryViewContext";

type SummaryViewProps = { groupedItems: GroupedLineItems };

export const SummaryView = ({ groupedItems }: SummaryViewProps) => {
  let groupedItemValues = Array.from(groupedItems.values()).sort((a, b) => {
    return b.count - a.count;
  });
  const { itemName, setItemName } = useSummaryViewContext();

  const handleItemClick = (clickedItemName: string) => {
    if (itemName !== "" && itemName === clickedItemName) {
      setItemName("");
      return;
    }
    setItemName(clickedItemName);
  };

  return (
    <div className="border-1 mr-6 bg-gray-900 rounded-2xl overflow-y-scroll h-full no-scrollbar">
      {groupedItemValues?.map((item) => {
        return (
          <button
            key={`${item.itemName}`}
            className={`${
              itemName === item.itemName ? "bg-gray-800" : "bg-[#111]"
            } w-[90%] flex border-2 rounded-xl border-gray-700 items-center mx-4 my-4 py-4 px-4`}
            onClick={() => {
              handleItemClick(item.itemName);
            }}
          >
            <div
              className={`${
                itemName === item.itemName ? "bg-gray-700" : "border-2"
              } w-8 h-8 rounded-lg flex items-center justify-center`}
            >
              <div className="text-white text-base font-bold">{item.count}</div>
            </div>
            <div className="ml-2">
              <div className={` text-white text-xl font-semibold`}>
                {item.itemName}
              </div>
            </div>
          </button>
        );
      })}
    </div>
  );
};
