import { useSummaryViewContext } from "../../../../../contexts/SummaryViewContext";
import { Tag } from "../../../../../ui/components/Tags/Tag";

type TicketSubLineItemProps = {
  name: string;
  quantity: number;
  status: string;
  isMarkReceivable: boolean;
  isLastLineItem: boolean;
  lineItemNumber: number;
  lineItemsTotalCount: number;
  parentStatus: string;
  onSubLineItemClick: () => Promise<void>;
};

export const TicketSubLineItem = ({
  name,
  quantity,
  status,
  isMarkReceivable,
  isLastLineItem,
  lineItemNumber,
  lineItemsTotalCount,
  parentStatus,
  onSubLineItemClick,
}: TicketSubLineItemProps) => {
  const isRemovalModifier = name.toLowerCase().startsWith("no");
  const itemName = isRemovalModifier ? name.toUpperCase() : name;
  const { disableKDSClick } = useSummaryViewContext();

  const handleClick = () => {
    return disableKDSClick ? () => {} : onSubLineItemClick();
  };
  return (
    <div
      className={`relative font-base text-xl py-4 flex w-full h-full flex-row items-center justify-between border-t border-gray-300 transition duration-700 ease-in-out ${getTicketSubLineItemColor(
        parentStatus,
        status,
        isMarkReceivable,
      )} ${isLastLineItem ? "mb-4 rounded-b-lg" : ""}`}
      onClick={handleClick}
    >
      <div className="w-full pl-[62px] pr-2 flex flex-row items-center justify-end overflow-hidden">
        <div className="w-full flex flex-row items-center">
          {isRemovalModifier && (
            <div className="ml-1.5">
              <Tag
                type="minus"
                iconColor="#F46722"
                tagStyling="outline-lfg-orange"
              />
            </div>
          )}
          {quantity > 1 && (
            <div className="py-[2px] px-2 bg-gray-100 border border-gray-500 flex flex-row items-center justify-center mr-2.5 rounded-md text-base font-inter font-extrabold ">
              {quantity}x
            </div>
          )}
          <div
            className={`${
              isRemovalModifier ? "text-lfg-orange ml-4" : ""
            } break-words`}
          >
            {itemName}
          </div>
        </div>
        {lineItemsTotalCount > 1 ? (
          <span className="mx-1 text-sm whitespace-nowrap">
            {lineItemNumber} of {lineItemsTotalCount}
          </span>
        ) : null}
      </div>
      {!isLastLineItem ? (
        <div
          className={`w-full pl-4 text-center absolute z-0 -bottom-6 bg-white text-gray-700 font-inter text-base font-bold border-t border-gray-300`}
        >
          Continues...
        </div>
      ) : null}
    </div>
  );
};

const getTicketSubLineItemColor = (
  parentStatus: string,
  status: string,
  isMarkReceivable: boolean,
) => {
  if (isMarkReceivable) {
    if (parentStatus === "CREATED") {
      return "bg-white";
    } else if (status === "CREATED") {
      return "bg-blue-200";
    } else {
      return "bg-green-200";
    }
  } else {
    switch (parentStatus) {
      case "RECEIVED":
        return "bg-green-200";
      case "READY":
        return "bg-blue-200";
      default:
        return "bg-white";
    }
  }
};
