type PlaySoundEffectProps = {
  audioSrc: string;
};

export const PlaySoundEffect = ({ audioSrc }: PlaySoundEffectProps) => {
  return (
    <audio autoPlay={true}>
      <source src={audioSrc} />
    </audio>
  );
};
