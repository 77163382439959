import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import { usePrepStations } from "../features/KitchenDisplaySystem/hooks/usePrepStations";
import { useLocationContext } from "./LocationContext";
import { useHistory, useLocation } from "react-router-dom";
import { useSegment } from "../utils/hooks/useSegment";
import { useEmployeeContext } from "./EmployeeContext";
import { CurrentFilter } from "../features/KitchenDisplaySystem/types";

export type PrepStationContextType = {
  currentPrepStationId: number | undefined;
  setCurrentPrepStationId: (val: number | undefined) => void;
  currentPrepStation: any;
  isExpo: boolean;
  setIsExpo: (isExpo: boolean) => void;
  sessionRole: "EXPO" | "COOK";
  prepStationData: any;
  refetchPrepStations: () => Promise<any>;
  loadingPrepStations: boolean;
  handlePrepStationChange?: (prepStation: any) => void;
  currentFilter: CurrentFilter;
  showAllStations: boolean;
  setCurrentFilter: (filter: CurrentFilter) => void;
  setShowAllStations: (showAllStations: boolean) => void;
};

export const PrepStationContext = createContext<PrepStationContextType>({
  currentPrepStationId: undefined,
  setCurrentPrepStationId: (val: number | undefined) => {},
  isExpo: false,
  setIsExpo: (isExpo: boolean) => {},
  sessionRole: "COOK",
  prepStationData: undefined,
  refetchPrepStations: () => Promise.resolve({}),
  loadingPrepStations: false,
  handlePrepStationChange: () => {},
  currentFilter: "cooking",
  showAllStations: false,
  setCurrentFilter: (currentFilter) => {},
  setShowAllStations: (showAllStations) => {},
  currentPrepStation: undefined,
});

type CurrentPrepStationProviderProps = {
  children: React.ReactElement;
};

export const PrepStationProvider = ({
  children,
}: CurrentPrepStationProviderProps) => {
  const [currentPrepStationId, setCurrentPrepStationId] = useState<
    number | undefined
  >(undefined);
  const [currentFilter, setCurrentFilter] = useState<CurrentFilter>("cooking");
  const [showAllStations, setShowAllStations] = useState(false);

  const routeLocation = useLocation();
  const history = useHistory();
  const { track } = useSegment();
  const { id: currentLocationId } = useLocationContext();
  const { employee } = useEmployeeContext();

  const {
    data: prepStationData,
    loading: loadingPrepStations,
    refetch: refetchPrepStations,
  } = usePrepStations(currentLocationId);

  const [isExpo, setIsExpo] = useState(false);

  const sessionRole = useMemo(() => {
    return isExpo ? "EXPO" : "COOK";
  }, [isExpo]);

  const handlePrepStationChange = (prepStation: any) => {
    setCurrentPrepStationId(prepStation?.id);
    history.push({ search: `?station=${prepStation?.name}` });

    window.localStorage.setItem("savedStation", prepStation?.name);

    const isExpo = prepStation.name === "Expo";

    track({
      event: "Clicked Prep Station",
      properties: {
        isExpo: isExpo,
        prepStationName: prepStation?.name,
        prepStationId: prepStation?.id,
        employee: employee,
      },
    });

    setIsExpo(isExpo);
    setShowAllStations(false);
    setCurrentFilter("cooking");
  };

  useEffect(() => {
    if (!isExpo && currentFilter === "scheduled") {
      setCurrentFilter("cooking");
    }
  }, [isExpo]);

  useEffect(() => {
    const searchParams = new URLSearchParams(routeLocation.search);
    let station = searchParams.get("station");

    if (!station) {
      station = window.localStorage.getItem("savedStation");
    }

    const [currentPrepStation] = (
      prepStationData?.location?.prep_stations || []
    ).filter(
      (prepStation: any) =>
        prepStation?.name?.replace(/ /g, "").toLowerCase() ==
        station?.replace(/ /g, "").toLowerCase(),
    );

    const newPrepStation =
      currentPrepStation || prepStationData?.location?.prep_stations[0];

    const isExpo = newPrepStation?.name === "Expo";

    setIsExpo(isExpo);
    setCurrentPrepStationId(newPrepStation?.id);
  }, [prepStationData, routeLocation.search]);

  const currentPrepStation = useMemo(() => {
    const prepStations = prepStationData?.location?.prep_stations || [];
    return prepStations?.find(
      (prepStation: any) => prepStation?.id === currentPrepStationId,
    );
  }, [currentPrepStationId, prepStationData]);

  const contextValue: PrepStationContextType = {
    currentPrepStationId,
    setCurrentPrepStationId,
    isExpo,
    setIsExpo,
    sessionRole,
    prepStationData,
    refetchPrepStations,
    loadingPrepStations,
    handlePrepStationChange,
    showAllStations,
    currentFilter,
    setCurrentFilter,
    setShowAllStations,
    currentPrepStation,
  };

  return (
    <PrepStationContext.Provider value={contextValue}>
      {children}
    </PrepStationContext.Provider>
  );
};

export const usePrepStationContext = () => {
  const prepStationContext = useContext(PrepStationContext);
  if (!prepStationContext) {
    throw new Error(
      "You may not use PrepStationContext outside of PrepStationProvider",
    );
  } else {
    return prepStationContext;
  }
};
