import React, { createContext, useCallback, useContext, useState } from "react";
import { Cook } from "../features/KitchenDisplaySystem/types";
import { useGetTicketsQuery } from "../graphql/generated";
import { useSegment } from "../utils/hooks/useSegment";
import { useEmployeeContext } from "./EmployeeContext";
import { useLocationContext } from "./LocationContext";
import { usePrepStationContext } from "./PrepStationContext";

export type CurrentCookContextType = {
  currentCook: Cook | undefined;
  onCookAuthentication: (cook: Cook | undefined) => void;
  onCookLogout: () => void;
  handleCookAuthentication: (pinCode: string) => Promise<{ status: boolean }>;
  setCurrentCook: (cook: Cook | undefined) => void;
};

export const CurrentCookContext = createContext<CurrentCookContextType>({
  currentCook: undefined,
  onCookAuthentication: () => {},
  onCookLogout: () => {},
  handleCookAuthentication: () => Promise.resolve({ status: false }),
  setCurrentCook: () => {},
});

export const CurrentCookProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { setEmployee, handleLogoutEmployee } = useEmployeeContext();
  const { track } = useSegment();
  const { id: currentLocationId } = useLocationContext();
  const [currentCook, setCurrentCook] = useState<Cook | undefined>();
  const { handleAuthenticateEmployee } = useEmployeeContext();
  const { sessionRole } = usePrepStationContext();
  const { refetch: refetchTicketData } = useGetTicketsQuery({
    variables: {
      locationId: currentLocationId,
    },
  });

  const handleCookAuthentication = useCallback(
    async (pinCode: string) => {
      try {
        refetchTicketData();

        const cookData = await handleAuthenticateEmployee({
          pinCode,
          locationId: currentLocationId || 0,
          device: "kds",
          sessionRoles: [sessionRole],
        });

        onCookAuthentication(cookData as Cook);
        return { status: true };
      } catch (err) {
        return { status: false };
      }
    },
    [handleAuthenticateEmployee, currentLocationId, sessionRole],
  );

  // Change current cook authenticated at prep statio
  const onCookAuthentication = useCallback(
    (cook: Cook | undefined) => {
      if (!cook) {
        return;
      }

      setEmployee(cook);
      track({
        event: "Login Employee",
        properties: { method: "pincode", employee: cook },
      });

      window.localStorage.setItem("currentCook", JSON.stringify(cook));
      setCurrentCook(cook);
    },
    [track],
  );

  // Change current cook authenticated at prep station
  const onCookLogout = async () => {
    if (!currentCook || !currentLocationId) {
      return null;
    }

    await handleLogoutEmployee({
      employeeId: currentCook.id,
      locationId: currentLocationId,
      device: "kds",
      employeeLoginId: currentCook.employee_login_id,
    });

    window.localStorage.removeItem("currentCook");
    setCurrentCook(undefined);
  };

  return (
    <CurrentCookContext.Provider
      value={{
        currentCook,
        onCookAuthentication,
        onCookLogout,
        handleCookAuthentication,
        setCurrentCook,
      }}
    >
      {children}
    </CurrentCookContext.Provider>
  );
};

export const useCurrentCookContext = () => {
  const currentCookContext = useContext(CurrentCookContext);

  if (!currentCookContext) {
    throw new Error(
      "You may not use CurrentCookContext outside of CurrentCookProvider",
    );
  } else {
    return currentCookContext;
  }
};
