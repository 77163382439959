import { ReactElement, createContext, useContext, useState } from "react";

export type AlertWidgetTemporaryContextType = {
  isAlertWidgetTemporaryVisible: boolean;
  setIsAlertWidgetTemporaryVisible: (val: boolean) => void;
  temporaryAlertMessage: string | ReactElement | undefined;
  setTemporaryAlertMessage: (val: any) => void;
  soundEffectSource: string;
  setSoundEffectSource: (audioSrc: string) => void;
};

export const AlertWidgetTemporaryContext =
  createContext<AlertWidgetTemporaryContextType>({
    isAlertWidgetTemporaryVisible: false,
    setIsAlertWidgetTemporaryVisible: () => {},
    temporaryAlertMessage: "",
    setTemporaryAlertMessage: () => {},
    soundEffectSource: "",
    setSoundEffectSource: () => {},
  });

type AlertWidgetTemporaryProviderProps = {
  children: JSX.Element;
  value?: AlertWidgetTemporaryContextType;
};

export const AlertWidgetTemporaryContextProvider = ({
  children,
  value,
}: AlertWidgetTemporaryProviderProps) => {
  const [isAlertWidgetTemporaryVisible, setIsAlertWidgetTemporaryVisible] =
    useState(false);
  const [temporaryAlertMessage, setTemporaryAlertMessage] = useState<
    string | ReactElement | undefined
  >(undefined);
  const [soundEffectSource, setSoundEffectSource] = useState<string>("");

  const contextValue: AlertWidgetTemporaryContextType = value || {
    isAlertWidgetTemporaryVisible,
    setIsAlertWidgetTemporaryVisible,
    temporaryAlertMessage,
    setTemporaryAlertMessage,
    soundEffectSource,
    setSoundEffectSource,
  };

  return (
    <AlertWidgetTemporaryContext.Provider value={contextValue}>
      {children}
    </AlertWidgetTemporaryContext.Provider>
  );
};

export const useAlertWidgetTemporaryContext = () => {
  const alertWidgetTemporaryContext = useContext(AlertWidgetTemporaryContext);
  if (!alertWidgetTemporaryContext) {
    throw new Error(
      "You may not use AlertWidgetTemporaryContext outside of AlertWidgetTemporaryProvider",
    );
  } else {
    return alertWidgetTemporaryContext;
  }
};
