export const getNumOrdersAndItemsByPrepStation = (
  prepStationData: any,
): Array<any> => {
  if (!prepStationData) return [];
  return prepStationData?.location.prep_stations.map(
    (prepStation: { name: any; tickets: any[] }) => {
      const order_ids = Array.from(
        new Set(
          prepStation.tickets
            .filter((ticket) => ticket.line_item.order.status === "COOKING")
            .map((ticket) => ticket.line_item.order.id),
        ),
      ); // find all the orders for which there are no items incomplete
      return {
        ...prepStation,
        numOrders: order_ids.filter((order_id) => {
          const incomplete_order_line_items = prepStation.tickets.filter(
            (ticket) =>
              ticket.line_item.order.id === order_id && !ticket.is_completed,
          );
          return incomplete_order_line_items.length > 0;
        }).length,
        numItems: prepStation.tickets.filter(
          (ticket) =>
            !ticket.is_completed && ticket.line_item.order.status === "COOKING",
        ).length,
      };
    },
  );
};
