import { gql, useMutation } from "@apollo/client";

const MOVE_SCHEDULED_ORDER_INTO_COOKING = gql`
  mutation MOVE_SCHEDULED_ORDER_INTO_COOKING($store_order_id: Int) {
    move_scheduled_order_into_cooking(store_order_id: $store_order_id)
  }
`;

export const useMoveScheduledOrderIntoCooking = () => {
  const [moveScheduledOrderIntoCooking, { loading, error }] = useMutation(
    MOVE_SCHEDULED_ORDER_INTO_COOKING,
  );

  return {
    moveScheduledOrderIntoCooking,
    loading,
    error,
  };
};
