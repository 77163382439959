import * as Icon from "react-feather";
import { NumberPadButtonV2 } from "./NumberPadButtonV2";

type NumberPadV2Props = {
  onNumberPadButtonClick: (event: any) => void;
};

export const NumberPadV2 = ({ onNumberPadButtonClick }: NumberPadV2Props) => {
  const numbers = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
  ];

  return (
    <div className="flex justify-center w-full">
      <table className="flex flex-col w-9/12">
        <tbody>
          {numbers.map((row: number[], index: number) => (
            <tr
              className="flex items-center my-8 justify-center"
              key={`row-${row}`}
            >
              {row.map((number: number) => (
                <NumberPadButtonV2
                  value={number}
                  key={number}
                  onClick={onNumberPadButtonClick}
                />
              ))}
            </tr>
          ))}
          <tr className="flex items-center justify-end">
            <NumberPadButtonV2
              value={0}
              key="0"
              onClick={onNumberPadButtonClick}
            />
            <NumberPadButtonV2
              value={"BACKSPACE"}
              key="BACKSPACE"
              onClick={onNumberPadButtonClick}
            >
              <Icon.ChevronLeft width={64} height={128} />
            </NumberPadButtonV2>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
