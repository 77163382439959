import { useCallback, useMemo } from "react";
import { useEmployeeContext } from "../../contexts/EmployeeContext";
import { Location } from "../../features/KitchenDisplaySystem/types";

type User = {
  user_id?: string;
  roles?: string[];
  name?: string;
  email?: string;
  email_verified?: boolean;
  sub?: string;
};

type IdentifyProps = {
  email: string;
  location: Location;
  user: User;
};

export const useSegment = () => {
  const { employee } = useEmployeeContext();

  const track = useCallback(
    ({ event, properties = {} }) => {
      analytics.track(event, {
        employee,
        ...properties,
      });
    },
    [employee],
  );

  const page = useCallback(({ name }) => {
    analytics.page(name);
  }, []);

  const identify = useCallback(({ email, location, user }: IdentifyProps) => {
    analytics.identify(email, {
      ...user,
      location: {
        id: location.id,
        slug: location.slug,
        name: location.name,
      },
    });
  }, []);

  return useMemo(() => ({ track, page, identify }), [track, page, identify]);
};
