import { useState } from "react";
import { AlertTriangle, Bell } from "react-feather";

type PeekImageViewProps = {
  buildGridImage?: string;
  finalPlatedImage?: string;
  packagingImage?: string;
  spanishMode: boolean;
  fallbackImage?: string;
};

export const PeekImageView = ({
  buildGridImage,
  finalPlatedImage,
  packagingImage,
  spanishMode,
  fallbackImage,
}: PeekImageViewProps) => {
  const [showDishImage, setShowDishImage] = useState(true);
  const [showFinalPlatedImage, setShowFinalPlatedImage] = useState(true);
  let imageToShow;

  if (buildGridImage) {
    imageToShow = buildGridImage;
  } else if (!showFinalPlatedImage) {
    imageToShow = finalPlatedImage;
  } else if (showFinalPlatedImage) {
    imageToShow = packagingImage;
  }

  return (
    <div className="w-full flex flex-col items-center justify-between bg-gray-800 rounded-xl border-2 border-gray-700">
      <div className="text-white font-inter font-bold	flex flex-row items-center justify-center m-2 px-2 py-5">
        <Bell
          className="mr-4 outline outline-2 outline-[#FFD953] outline-offset-4 rounded-sm"
          color="#FFD953"
          size={16}
        />
        {spanishMode
          ? "La foto no incluye modificadores"
          : "Image doesn't include modifiers"}
      </div>
      {showDishImage && !imageToShow && (
        <div className="w-full px-4 mb-4">
          <div className="flex border-4 rounded border-yellow-300 bg-yellow-100 p-4 w-full gap-2">
            <AlertTriangle /> Fallback: Guest-facing image is shown!
          </div>
        </div>
      )}

      <img
        src={`${(imageToShow || fallbackImage)?.replace(
          ".webp",
          "-640w.webp",
        )}`}
        style={{
          maxWidth: "850px",
          maxHeight: "600px",
        }}
        className="object-contain"
        onClick={() => setShowDishImage(!showDishImage)}
      />
      {(finalPlatedImage || packagingImage) && (
        <div className="text-white font-inter text-xl font-semibold w-full flex flex-row justify-center items-center divide-x-2 divide-gray-700">
          <button
            className={`${
              showFinalPlatedImage ? "bg-gray-800" : "bg-black"
            } flex flex-row justify-center items-center w-1/2 h-full rounded-bl-xl py-5 px-2`}
            onClick={() => setShowFinalPlatedImage(true)}
          >
            Dish Photo
          </button>
          <button
            className={`${
              !showFinalPlatedImage ? "bg-gray-800" : "bg-black"
            } flex flex-row justify-center items-center w-1/2 h-full rounded-br-xl py-5 px-2`}
            onClick={() => setShowFinalPlatedImage(false)}
            disabled={!packagingImage}
          >
            Packaging Photo
          </button>
        </div>
      )}
    </div>
  );
};
