import { Bell } from "react-feather";

export const NewScheduledAlertWidget = ({
  onClick,
}: {
  onClick?: Function;
}) => {
  return (
    <div className="flex flex-col">
      <div className={"mb-4 flex flex-row items-center"}>
        <Bell
          className="mr-4 outline outline-[3px] outline-[#FFD953] outline-offset-4 rounded-sm"
          color="#FFD953"
          size={16}
        />
        <p>New large scheduled order needs your attention</p>
      </div>
      <button
        className={
          "border-[2px] border-[#585858] w-fit rounded-full bg-white text-black text-[22px] px-8 py-2"
        }
        onClick={() => onClick?.()}
      >
        View Order
      </button>
    </div>
  );
};
