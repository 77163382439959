import { useAlertWidgetTemporaryContext } from "../../../../../contexts/AlertWidgetTemporaryContext";
import { useSummaryViewContext } from "../../../../../contexts/SummaryViewContext";
import { KdsTicketLineItem } from "../../../../../graphql/generated";
import { ErrorAlertWidget } from "../../v2/AlertWidget/ErrorAlertWidget";
import { TicketLineItem } from "./TicketLineItem";

type TicketLineItemControllerProps = {
  ticketLineItem: KdsTicketLineItem;
  isLastLineItem: boolean;
  updateLineItemStatus: (
    ticket_line_item_id: number,
    status: string,
    printChit: boolean,
    markReceived: boolean,
    targetId: number,
    targetType: string,
  ) => Promise<void>;
  ticketStatus: string;
  setShowPeek: React.Dispatch<React.SetStateAction<boolean>>;
  setPeekId: React.Dispatch<React.SetStateAction<number>>;
  showPeekIcon: boolean;
  lineItemUpdateLoading: boolean;
  showLoadingIcon: boolean;
};

export const TicketLineItemController = ({
  ticketLineItem,
  isLastLineItem,
  updateLineItemStatus,
  ticketStatus,
  setShowPeek,
  setPeekId,
  showPeekIcon,
  lineItemUpdateLoading,
  showLoadingIcon,
}: TicketLineItemControllerProps) => {
  const { setIsAlertWidgetTemporaryVisible, setTemporaryAlertMessage } =
    useAlertWidgetTemporaryContext();
  const {
    setIsEnabled: setSummaryViewEnabled,
    setItemName: setSummaryViewItemName,
  } = useSummaryViewContext();

  const onLineItemClick = async () => {
    if (ticketStatus === "SCHEDULED") return;
    if (ticketStatus === "READY" && !lineItemUpdateLoading) {
      setIsAlertWidgetTemporaryVisible(true);
      setTemporaryAlertMessage(
        <ErrorAlertWidget
          message={"Unready the order before marking items incomplete"}
        />,
      );
      setTimeout(() => {
        setTemporaryAlertMessage("");
        setIsAlertWidgetTemporaryVisible(false);
      }, 3000);
    } else {
      updateLineItemStatus(
        ticketLineItem.id ?? 0,
        ticketLineItem?.status ?? "",
        ticketLineItem.print_chit ?? true,
        ticketLineItem.mark_received ?? true,
        ticketLineItem.target_id ?? 0,
        ticketLineItem.target_type ?? "",
      );
    }
  };

  const onPeekClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setShowPeek(true);
    setSummaryViewEnabled(false);
    setSummaryViewItemName("");
    setPeekId(ticketLineItem.id ?? 0);
  };

  return (
    <TicketLineItem
      name={ticketLineItem.name ?? ""}
      quantity={ticketLineItem.quantity ?? 0}
      status={ticketLineItem.status ?? ""}
      isLastLineItem={isLastLineItem}
      onPeekClick={onPeekClick}
      onLineItemClick={onLineItemClick}
      showPeekIcon={
        showPeekIcon && ticketLineItem.target_type === "STORE_ORDER_LINE_ITEM"
      }
      showLoadingIcon={showLoadingIcon}
    />
  );
};
