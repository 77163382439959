import { useCurrentCookContext } from "../../../../contexts/CurrentCookContext";
import { usePrepStationContext } from "../../../../contexts/PrepStationContext";
import { getNumOrdersAndItemsByPrepStation } from "../../utils/getNumOrdersAndItemsByPrepStation";

export default function ExpoAllStationView() {
  const { isExpo, prepStationData, handlePrepStationChange } =
    usePrepStationContext();
  const { onCookLogout } = useCurrentCookContext();

  return (
    <div className="flex h-32 w-full grow flex-col rounded-xl border-2 border-gray-700 bg-black py-8 px-16 text-white">
      <div className="flex w-full flex-row">
        <div className="font-inter grow px-5 text-[16px] font-bold text-gray-700">
          Station
        </div>
        <div className="font-inter w-56 flex-none text-[16px] font-bold text-gray-700">
          Orders
        </div>
        <div className="font-inter w-40 flex-none text-[16px] font-bold text-gray-700">
          Items
        </div>
        <div className="w-[116px]"></div>
      </div>
      <div className="flex w-full flex-col items-center justify-start divide-y divide-gray-800 overflow-y-auto no-scrollbar">
        {getNumOrdersAndItemsByPrepStation(prepStationData)
          .sort((stationA, stationB) =>
            stationA.numItems > stationB.numItems ? -1 : 0,
          )
          .map((station) => (
            <div className="flex w-full flex-row py-8" key={station.name}>
              <div className="font-inter grow px-5 text-[28px] font-medium text-white">
                {station.name}
              </div>
              <div className="font-inter w-56 flex-none text-[28px] font-medium text-white">
                {station.numOrders}
              </div>
              <div className="font-inter w-40 flex-none text-[28px] font-medium text-white">
                {station.numItems}
              </div>
              <button
                onClick={() => {
                  handlePrepStationChange?.(station);

                  if (isExpo || station.name === "Expo") {
                    onCookLogout();
                  }
                }}
                className="text-whiteborder-2 h-[56px] w-[116px] rounded-[100px] border-2 border-gray-700 text-[22px]"
              >
                Open
              </button>
            </div>
          ))}
      </div>
    </div>
  );
}
