import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ScheduledOrderToCookingAlertWidget } from "../features/KitchenDisplaySystem/components/v2/AlertWidget/ScheduledOrderToCookingAlertWidget";
import { ViewedScheduledAlertWidget } from "../features/KitchenDisplaySystem/components/v2/AlertWidget/ViewedScheduledAlertWidget";
import { NewScheduledAlertWidget } from "../features/KitchenDisplaySystem/components/v2/NewScheduledAlertWidget";
import { useAcknowledgeOrderNotification } from "../features/KitchenDisplaySystem/hooks/useAcknowledgeOrderNotification";
import { useMoveCookingOrderBackIntoScheduled } from "../features/KitchenDisplaySystem/hooks/useMoveCookingOrderBackIntoScheduled";
import { useMoveScheduledOrderIntoCooking } from "../features/KitchenDisplaySystem/hooks/useMoveScheduledOrderIntoCooking";
import { useScheduledOrderNotifications } from "../features/KitchenDisplaySystem/hooks/useScheduledOrderNotifications";
import { useGetTicketsQuery } from "../graphql/generated";
import { useSegment } from "../utils/hooks/useSegment";
import { useAlertWidgetTemporaryContext } from "./AlertWidgetTemporaryContext";
import { useCurrentCookContext } from "./CurrentCookContext";
import { useLocationContext } from "./LocationContext";
import { usePrepStationContext } from "./PrepStationContext";

type ScheduledOrdersContextType = {
  newScheduledOrderId?: number;
  movedScheduledOrderId?: number;
  scheduledOrders: Array<any>[];
  setScheduledOrders: (scheduledOrders: any) => void;
  scheduledPages?: any;
  setScheduledPages: any;
  doMoveScheduledOrderIntoCooking: (storeOrderId: number) => void;
  scheduledCount: number;
  scheduledOrderNotificationPage?: number;
  doDisplayViewedScheduledAlertWidget: () => void;
};

export const ScheduledOrdersContext = createContext<ScheduledOrdersContextType>(
  {
    newScheduledOrderId: undefined,
    movedScheduledOrderId: undefined,
    scheduledOrders: [],
    setScheduledOrders: () => {},
    scheduledPages: undefined,
    setScheduledPages: () => {},
    doMoveScheduledOrderIntoCooking: () => {},
    scheduledCount: 0,
    scheduledOrderNotificationPage: undefined,
    doDisplayViewedScheduledAlertWidget: () => {},
  },
);

export const ScheduledOrdersProvider = ({
  children,
}: PropsWithChildren<React.ReactElement | JSX.Element | any>) => {
  const { currentPrepStationId, isExpo, setCurrentFilter, currentFilter } =
    usePrepStationContext();
  const { id: currentLocationId, slug: currentLocation } = useLocationContext();
  const {
    setIsAlertWidgetTemporaryVisible,
    setTemporaryAlertMessage,
    temporaryAlertMessage,
  } = useAlertWidgetTemporaryContext();
  const { currentCook } = useCurrentCookContext();

  const { track } = useSegment();
  const {
    data: scheduledOrderNotifications,
    startPolling,
    stopPolling,
  } = useScheduledOrderNotifications(currentPrepStationId);

  const { acknowledgeNotificationMutation } = useAcknowledgeOrderNotification();

  const { refetch: refetchRefactorTicketData } = useGetTicketsQuery({
    variables: {
      locationId: currentLocationId,
    },
  });
  const { moveScheduledOrderIntoCooking } = useMoveScheduledOrderIntoCooking();
  const { moveCookingOrderBackIntoScheduled } =
    useMoveCookingOrderBackIntoScheduled();

  const [scheduledOrders, setScheduledOrders] = useState<Array<any>>([]);
  const [newScheduledOrderId, setNewScheduledOrderId] = useState<number>();
  const [movedScheduledOrderId, setMovedScheduledOrderId] = useState<number>();
  const [scheduledPages, setScheduledPages] = useState<any>([]);

  const [
    scheduledOrderNotificationClicked,
    setScheduledOrderNotificationClicked,
  ] = useState<boolean>(false);

  const handleSetScheduledPages = (_scheduledPages: any) => {
    if (JSON.stringify(scheduledPages) != JSON.stringify(_scheduledPages)) {
      handleSetScheduledPages(_scheduledPages);
    }
  };

  const doDisplayViewedScheduledAlertWidget = () => {
    setTemporaryAlertMessage(<ViewedScheduledAlertWidget />);
    setTimeout(() => {
      setIsAlertWidgetTemporaryVisible(false);
      setTemporaryAlertMessage(undefined);
      handleDismissViewedNotification();
    }, 4000);
  };

  const handleDismissViewedNotification = useCallback(() => {
    setScheduledOrderNotificationClicked(false);
    setNewScheduledOrderId(undefined);
    setScheduledOrderNotificationClicked(false);
  }, [
    setScheduledOrderNotificationClicked,
    setScheduledOrderNotificationClicked,
    setNewScheduledOrderId,
  ]);

  const handleScheduledOrderNotificationClick = () => {
    track({
      event: "New Scheduled Order Notification Click",
      properties: {
        currentPrepStationId,
        currentLocation,
      },
    });

    setScheduledOrderNotificationClicked(true);
  };

  useEffect(() => {
    if (!!currentCook && isExpo) {
      startPolling(1000);
    } else {
      stopPolling();
      setIsAlertWidgetTemporaryVisible(false);
      setTemporaryAlertMessage(null);
      setNewScheduledOrderId(undefined);
      setMovedScheduledOrderId(undefined);
      setScheduledOrderNotificationClicked(false);
    }
  }, [currentCook, isExpo]);

  useEffect(() => {
    if (scheduledOrderNotificationClicked) {
      setCurrentFilter("scheduled");
    }
  }, [scheduledOrderNotificationClicked, setCurrentFilter]);

  const doMoveCookingOrderBackIntoScheduled = async (
    scheduledOrderId: number,
    refetchDelay = 250,
  ) => {
    console.log(scheduledOrderId);
    await moveCookingOrderBackIntoScheduled({
      variables: {
        store_order_id: scheduledOrderId,
      },
    });

    setTemporaryAlertMessage(null);
    setIsAlertWidgetTemporaryVisible(false);
    setTemporaryAlertMessage(null);
    setMovedScheduledOrderId(undefined);
    setTimeout(() => {
      refetchRefactorTicketData();
    }, refetchDelay);
  };

  const handleShowMoveScheduledOrderAlertWidget = (storeOrderId: number) => {
    setIsAlertWidgetTemporaryVisible(true);
    setTemporaryAlertMessage(
      <ScheduledOrderToCookingAlertWidget
        onCancel={() => doMoveCookingOrderBackIntoScheduled(storeOrderId)}
      />,
    );
    setTimeout(() => {
      setIsAlertWidgetTemporaryVisible(false);
      setTemporaryAlertMessage(null);
      setNewScheduledOrderId(undefined);
      setMovedScheduledOrderId(undefined);
      setScheduledOrderNotificationClicked(false);
    }, 10 * 1000);
  };

  const doMoveScheduledOrderIntoCooking = (
    storeOrderId: number,
    refetchDelay = 750,
  ) => {
    setMovedScheduledOrderId(storeOrderId);

    moveScheduledOrderIntoCooking({
      variables: {
        store_order_id: storeOrderId,
      },
    }).then(() => {
      setTimeout(
        () => handleShowMoveScheduledOrderAlertWidget(storeOrderId),
        refetchDelay + 50,
      );
      setTimeout(() => {
        refetchRefactorTicketData();
      }, refetchDelay);
    });
  };

  const getScheduledPageForNotification = (scheduledOrderId: any) => {
    if (scheduledPages.length < 1) {
      return 0;
    }

    const scheduledOrderNotificationPage = scheduledPages.findIndex(
      (page: any, index: number) => {
        const matchPage = page?.find((column: any) => {
          return column?.find((ticket: any) => {
            return ticket?.id === scheduledOrderId;
          });
        });

        if (matchPage) {
          return true;
        }

        return false;
      },
    );

    return Math.max(scheduledOrderNotificationPage, 0);
  };

  useEffect(() => {
    const orderNotifications: { [key: string]: any } = {};
    scheduledOrderNotifications?.forEach((notification) => {
      let storeOrderId = notification?.order?.store_order_id;
      if (storeOrderId) {
        orderNotifications[storeOrderId.toString()] = notification?.id;
      }
    });
    let scheduledOrderId: number | undefined = undefined;

    scheduledOrders?.forEach((order) => {
      const storeOrderId: number = order?.id as number;
      if (storeOrderId.toString() in orderNotifications) {
        const notificationId = orderNotifications[storeOrderId.toString()];
        if (!scheduledOrderId) {
          scheduledOrderId = storeOrderId;
        }

        acknowledgeNotificationMutation({
          variables: {
            notificationId: notificationId,
          },
        });
      }

      if (scheduledOrderId) {
        setIsAlertWidgetTemporaryVisible(true);
        let _scheduledOrderNotificationPage =
          getScheduledPageForNotification(scheduledOrderId);

        setNewScheduledOrderId(scheduledOrderId);
        const alertDiv = (
          <NewScheduledAlertWidget
            onClick={handleScheduledOrderNotificationClick}
          />
        );

        setTemporaryAlertMessage(alertDiv);
      } else if (
        setIsAlertWidgetTemporaryVisible &&
        temporaryAlertMessage &&
        scheduledOrderNotifications?.length
      ) {
        setTemporaryAlertMessage(undefined);
        setIsAlertWidgetTemporaryVisible(false);
      }
    });
  }, [scheduledOrders, scheduledOrderNotifications]);

  return (
    <ScheduledOrdersContext.Provider
      value={{
        scheduledOrders,
        setScheduledOrders,
        newScheduledOrderId,
        movedScheduledOrderId,
        scheduledPages,
        setScheduledPages,
        doMoveScheduledOrderIntoCooking,
        scheduledCount: scheduledOrders.length,
        doDisplayViewedScheduledAlertWidget,
      }}
    >
      {children}
    </ScheduledOrdersContext.Provider>
  );
};

export const useScheduledOrdersContext = () => {
  const scheduledOrdersContext = useContext(ScheduledOrdersContext);
  if (!scheduledOrdersContext) {
    throw new Error(
      "You may not use ScheduledOrderContext outside of ScheduledContextProvider",
    );
  }

  return scheduledOrdersContext;
};
