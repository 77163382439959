import React, { createContext, useContext, useState } from "react";

export type AppMetadataContextType = {
  isAdmin: boolean;
  setIsAdmin: (val: boolean) => void;
};

export const AppMetadataContext = createContext<AppMetadataContextType>({
  isAdmin: false,
  setIsAdmin: () => {},
});

type AppMetadataProviderProps = {
  children: React.ReactElement;
};

export const AppMetadataProvider = ({ children }: AppMetadataProviderProps) => {
  const [isAdmin, setIsAdmin] = useState(false);

  const contextValue: AppMetadataContextType = {
    isAdmin,
    setIsAdmin,
  };

  return (
    <AppMetadataContext.Provider value={contextValue}>
      {children}
    </AppMetadataContext.Provider>
  );
};

export const useAppMetadataContext = () => {
  const appMetadataContext = useContext(AppMetadataContext);
  if (!appMetadataContext) {
    throw new Error(
      "You may not use AppMetadataContext outside of AppMetadataProvider",
    );
  } else {
    return appMetadataContext;
  }
};
