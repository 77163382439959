import { gql, useQuery } from "@apollo/client";

const PREP_STATIONS_WITH_ORDER_QUERY = gql`
  query PREP_STATIONS_WITH_ORDER_QUERY($location_id: Int) {
    location(location_id: $location_id) {
      id
      prep_stations {
        id
        name
        tickets {
          is_completed
          line_item {
            order {
              id
              status
            }
          }
        }
      }
    }
  }
`;

export const usePrepStationsWithOrders = (locationId?: number) => {
  const { data, error, loading, refetch } = useQuery(
    PREP_STATIONS_WITH_ORDER_QUERY,
    {
      variables: { location_id: locationId },
      skip: locationId === undefined,
      pollInterval: 60 * 1000,
    },
  );

  return { data, error, loading, refetch };
};
