import { usePrepStationsWithOrders } from "../../hooks/usePrepStationWithOrders";
import { getNumOrdersAndItemsByPrepStation } from "../../utils/getNumOrdersAndItemsByPrepStation";
import { usePrepStationContext } from "../../../../contexts/PrepStationContext";
import { useLocationContext } from "../../../../contexts/LocationContext";

type LoginPageStationsViewProps = {
  setShowLoginStationPicker: any;
};

export const LoginPageStationsView = ({
  setShowLoginStationPicker,
}: LoginPageStationsViewProps) => {
  const { isExpo, currentPrepStationId } = usePrepStationContext();
  const { id: currentLocationId } = useLocationContext();
  const {
    data: prepStationData,
    loading,
    refetch: refetchPrepStations,
  } = usePrepStationsWithOrders(currentLocationId);

  const [currentPrepStation] = (
    prepStationData?.location?.prep_stations || []
  ).filter((prepStation: any) => prepStation.id === currentPrepStationId);
  if (loading) return <></>;
  return (
    <div className="flex flex-col bg-gray-800 pb-20">
      <div className="flex flex-col items-center justify-center pt-20 pb-14 text-white">
        <div
          onClick={() => setShowLoginStationPicker(true)}
          className="whitespace-no-wrap min-w-24 flex h-20 items-center justify-center rounded-xl border-2 border-gray-700 bg-black px-4 text-center text-xl font-semibold"
        >
          {currentPrepStation?.name}
        </div>

        <div
          className=" text-white"
          style={{
            fontFamily: "Inter",
            fontSize: "96px",
            fontWeight: "700",
          }}
        >
          {
            getNumOrdersAndItemsByPrepStation(prepStationData).filter(
              (prepStation) => prepStation.name === currentPrepStation.name,
            )[0].numItems
          }
        </div>
        <div className="font-inter text-[28px] font-medium text-white">
          {`Items at ${currentPrepStation.name}`}
        </div>
      </div>
      <div className="flex flex-1 flex-col px-20">
        <div className="flex justify-center">
          <hr className="border-1 h-px w-32 flex-1 border-gray-700 bg-gray-700 dark:bg-gray-700"></hr>
        </div>
        <div className="flex flex-1 flex-col justify-evenly">
          {getNumOrdersAndItemsByPrepStation(prepStationData)
            .filter((prepStation: any) => prepStation?.name !== "Expo")
            .sort((stationA, stationB) =>
              stationA.numItems > stationB.numItems ? -1 : 0,
            )
            .slice(0, 3)
            .map((station: any) => (
              <div
                key={`station-${station.id}`}
                className="font-inter flex items-center justify-between text-[28px] font-normal text-white"
              >
                <div className="flex"> {station.name}</div>
                <div className="flex flex-none pl-8">{station.numItems}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
