import { Dispatch, SetStateAction } from "react";
import { useSegment } from "../../../../utils/hooks/useSegment";
import { usePrepStationsWithOrders } from "../../hooks/usePrepStationWithOrders";

type StationDropdownV2Props = {
  currentLocationId: number;
  currentPrepStationId?: number;
  prepStations: Array<any>;
  onPrepStationChange: any;
  isExpo: boolean;
  onCookLogout: () => void;
  showAllStations: boolean;
  setShowAllStations: Dispatch<SetStateAction<boolean>>;
};

export const StationDropdownV2 = ({
  currentPrepStationId,
  prepStations,
  showAllStations,
  setShowAllStations,
  currentLocationId,
}: StationDropdownV2Props) => {
  const { track } = useSegment();

  const [currentPrepStation] = (prepStations || []).filter(
    (prepStation: any) => prepStation.id === currentPrepStationId,
  );

  const { refetch: refetchPrepStations } =
    usePrepStationsWithOrders(currentLocationId);

  return (
    <>
      <div className="inline-flex">
        <div className="relative">
          <button
            type="button"
            className={`${
              showAllStations ? "bg-gray-800" : "bg-black"
            } inline-flex h-full max-w-[200px] items-center justify-center rounded-xl border-2 border-gray-700 px-4 text-gray-200`}
            onClick={() => {
              track({
                event: "Clicked Station Dropdown",
                properties: {
                  show: !showAllStations,
                },
              });
              refetchPrepStations();
              setShowAllStations(!showAllStations);
            }}
          >
            <p className="font-inter truncate text-[22px]	font-semibold">
              {currentPrepStation?.name}
            </p>
          </button>
        </div>
      </div>
    </>
  );
};
