export const PrepStationLoginArrow = () => {
  return (
    <svg
      width="167"
      height="64"
      viewBox="0 0 167 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3C20.6074 6.74901 7.85648 29.619 6.4595 40.4538C1.1457 81.6669 112.823 48.7486 129.194 42.2635C132.204 41.0713 159.304 28.0193 158.207 27.3921C155.571 25.8848 138.738 23.1432 143.425 23.1432C150.35 23.1432 156.902 25.9758 163.947 25.9758C167.448 25.9758 151.88 49.208 150.502 52.8858"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
      />
    </svg>
  );
};
